import Image from 'next/image'
import Link from 'next/link'
import { ExternalIcon } from '../icons'
import { RichText } from 'prismic-reactjs'

interface Props {
  title: string;
  externalUrl: string;
  imageUrl: string;
  imageAlt: string;
  partnerLogoUrl: string;
  partnerLogoAlt: string;
  partnerDescription: any;
}

export const HeroPartner = ({ title, externalUrl, imageUrl, imageAlt, partnerLogoUrl, partnerLogoAlt, partnerDescription }: Props) => {
  const domain = (new URL(externalUrl))

  return (
    <header className='hero-partner'>
      <div className='hero-partner__container'>
        <header className='hero-partner__details'>
          {partnerLogoUrl &&
            <div className='hero-partner__details-header'>
              <div className='hero-partner__details-header-logo'>
                <span className='next-img-wrap'>
                  <Image
                    width={720}
                    height={420}
                    layout={'fill'}
                    objectFit={'contain'}
                    objectPosition={'center'}
                    src={partnerLogoUrl}
                    alt={partnerLogoAlt} />
                </span>
              </div>
              <div className='hero-partner__details-header-partner'>
                <RichText render={partnerDescription} />
              </div>
            </div>
          }
          <div className='hero-partner__details-body'>
            <h1 className='hero-partner__details-body-title'>{title}</h1>
          </div>
          <div className='hero-partner__details-footer'>
            <a className='hero-partner__details-footer-link' href={externalUrl} target="_blank" rel="noreferrer">
              see complete details at {domain.hostname} <ExternalIcon />
            </a>
          </div>
        </header>
        <section className='hero-partner__img'>
          <Image
            width={720}
            height={420}
            layout={'fill'}
            objectFit={'cover'}
            objectPosition={'center'}
            src={imageUrl || '/assets/img/dev-only/article/article-hero-partner@2x.jpg'}
            alt={imageAlt} />
        </section>
      </div>
    </header>
  )
}
