interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const ToggleOff = ({ width = 32, height = 18 , classNames = 'icon' }: Props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 18"
      className={classNames}
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g transform='translate(-1082 -642)'>
          <g transform='translate(1082 642)'>
            <rect
              width='32'
              height='18'
              x='0'
              y='0'
              fill='#7F8486'
              rx='9'
            ></rect>
            <circle cx='9' cy='9' r='7' fill='#FFF'></circle>
          </g>
        </g>
      </g>
    </svg>
  );
}
