import { useEffect, useState } from 'react'
import { MagnifyingGlassIcon, MobileMenuCloseIcon } from '../icons'

interface Props {
  query: string
  onSubmit: (query: string) => void
}

export const Search = ({ query, onSubmit }: Props) => {
  const [value, setValue] = useState(query)

  useEffect(() => {
    if (query !== value) setValue(query)
  }, [query])

  return (
    <div className='search'>
      <span className='search__magnify-wrap'><MagnifyingGlassIcon /></span>
      <input
        className='search__input'
        type='text'
        value={value ?? ''}
        placeholder='What are you looking for today?'
        onChange={e => setValue(e.target.value)}
        onKeyUp={e => {
          if (e.key === 'Enter') onSubmit(value)
        }} />
      {value && (
        <button
          className='search__reset-button'
          onClick={() => {
            setValue('')
            onSubmit('')
          }}>
          <MobileMenuCloseIcon width={18} height={18} />
          <span>clear</span>
        </button>
      )}
      <button
        className='search__submit-button'
        onClick={() => onSubmit(value)}>
        search
      </button>
    </div>
  )
}
