import { useAnalyticsPageView, useSearchResponse, useUserProfile } from '../../lib/state'
import { Card, CardSelect } from '../cards'

const topicsChallenges = {
  'siargd': 'YjydnxAAAPUDuVDN', // Professional Opportunities : Enhancing Your Interviewing Skills
  '7f0517': 'YjyVshAAAHIJuSxu', // Sales : Referral Strategies
  '32zkvu': 'Yjy13xAAACEAua1K', // Marketing : Getting the Most Out of Social Media
  'jfx53l': 'YjzSnxAAACIAujDu', // Community : The Importance of Mentorship
  '8flqk0': 'YjyZJxAAACEAuTwH', // Virtual Environment : Tools for Delivering Great Online Experiences
  'qamyrc': 'YjyNzxAAACEAuQdv', // Starting a Business : Starting Your Career with a Business Mindset
  'cus0xy': 'YjyQMxAAAPUDuRIL', // Personal Development : Achieving Work-Life Balance
}

interface Props {
  selectedIds: string[]
  onSelect: (id: string, topicIds: string[]) => void
  onUnselect: (id: string) => void
  selectedTopicIds: string[]
}

export const ChallengesPanel = ({ selectedIds, onSelect, onUnselect, selectedTopicIds }: Props) => {
  // const [userProfile] = useUserProfile()
  // const { certifications } = userProfile || {}

  const includeIds = process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
    ? ['YjyPvBAAAHIJuQ_p', 'Yjy4sxAAACEAubpZ', topicsChallenges[selectedTopicIds[0]]]
    : ['YddF9RMAACcAd3Ef', 'YdiHjRMAACYAfN5t', 'YdiMjBMAACUAfPQa']
  const [response, loading, error] = useSearchResponse({ includeIds })

  useAnalyticsPageView({ title: 'Challenges', type: 'Onboarding Final' })

  return (
    <>
      <header className='onboard__panel-header'>
        <h2>Accept your first Compass Challenge</h2>
        <p>With ACE&reg; Pro Compass, you&apos;re encouraged to complete Challenges that involve reading, watching, listening and responding to content that is directly applicable to your life as an ACE Certified Professional. Based on what we know so far, we think these may be helpful to you.</p>
      </header>
      <section className='onboard__panel-content'>
        <div className='grid'>
          {response?.hits.map(hit => (
            <Card data={hit} key={hit.objectID}>
              <CardSelect
                selected={selectedIds.includes(hit.objectID)}
                onChange={selected => selected ? onSelect(hit.objectID, hit.topic_ids) : onUnselect(hit.objectID)} />
            </Card>
          ))}
        </div>
      </section>
    </>
  )
}
