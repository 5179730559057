import { experiences } from '../../lib/fields'
import { useAnalytics, useAnalyticsPageView, useUserProfileState } from '../../lib/state'
import { snakeCase } from '../../lib/util'
import { FieldValuesSelect } from '../forms'
import { CheckmarkIcon } from '../icons'

export const ExperiencePanel = () => {
  const [userProfile, setUserProfile] = useUserProfileState()

  const analytics = useAnalytics()
  useAnalyticsPageView({ title: 'Experience', type: 'Onboarding' })

  return (
    <>
      <header className='onboard__panel-header'>
        <h2>Experience</h2>
        <p>Every journey is different. Whether you&apos;re a seasoned pro or just starting out, ACE&reg; Pro Compass will point you toward a career path that&apos;s unique to your experience. How long have you been a part of the industry?</p>
      </header>
      <section className='onboard__panel-content'>
        <FieldValuesSelect
          values={experiences.values}
          selected={userProfile?.experience}
          onSelect={experience => {
            setUserProfile({ ...userProfile, experience })
            analytics('link', {
              'event_type': 'onboarding',
              [snakeCase('experience')]: experience,
            })
          }}
          baseClassName={'onboard-options-grid'}
          iconOn={<CheckmarkIcon />}
          iconOff={<CheckmarkIcon />} />
      </section>
    </>
  )
}
