interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const CheckboxIcon = ({ width = 32, height = 32 , classNames = 'icon' }: Props) => {

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 32 32'
      className={classNames}
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g transform='translate(-160 -5479)'>
          <g transform='translate(160 5479)'>
            <rect
              className='checkbox'
              width='30.75'
              height='30.75'
              x='0.625'
              y='0.625'
              fill='#FFF'
              stroke='#5282DE'
              strokeWidth='1.25'
              rx='2'
            ></rect>
            <path
              className='checkmark'
              fill='#43BEAC'
              d='M26.5387293 5L12.9982594 19.9605129 7.46127067 13.8382048 5 16.5579483 12.9982594 25.4 29 7.71974354z'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
