import { onAuthStateChanged, User as FirebaseUser } from 'firebase/auth'
import { useEffect } from 'react'
import firebase from '../../lib/firebase'
import { User } from '../../lib/models'
import { useAnalytics, useSetAuth, useSetUser } from '../../lib/state'

const convertUser = (user: FirebaseUser): User => {
  if (!user) return user
  const { displayName, email, photoURL, uid } = user
  return { displayName, email, photoURL, uid }
}

export const FirebaseAuthObserver = () => {
  const setAuth = useSetAuth()
  const setUser = useSetUser()
  const analytics = useAnalytics()

  useEffect(() => {
    return onAuthStateChanged(firebase.auth, user => {
      setAuth(!!user)
      setUser(convertUser(user))
      if (user) {
        analytics('link', {
          'event_type': 'login',
          'method': 'ace',
        })
      }
    })
  }, [])

  return null
}
