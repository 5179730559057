import { Popover } from '@headlessui/react'
import { Value } from '../../lib/fields'
import { DocumentType } from '../../lib/prismic'
import { FieldValuesMultipleSelect } from '../forms'
import { ChevronDownIcon, ToggleCheckboxOff, ToggleCheckboxOn } from '../icons'

const values: Value[] = [
  { id: DocumentType.Article, name: 'Article' },
  { id: DocumentType.Video, name: 'Video' },
  { id: DocumentType.Audio, name: 'Audio' },
  { id: DocumentType.Resource, name: 'Resource' },
  { id: DocumentType.CourseResource, name: 'Course Resource' },
  { id: DocumentType.PartnerResource, name: 'Partner Resource' },
  { id: DocumentType.Challenge, name: 'Challenge' },
]

interface Props {
  selected: string[]
  onSelect(selected: string[]): void
}

export const TypesFilter = ({ selected, onSelect }: Props) => {
  return (
    <Popover className='types-filter'>
      <Popover.Button className='types-filter__button'>Types<ChevronDownIcon/></Popover.Button>

      <Popover.Panel className='types-filter__panel'>
        <div>
          <FieldValuesMultipleSelect
            values={values}
            selected={selected}
            onSelect={onSelect}
            baseClassName={'types-filter-group-options'}
            iconOff={<ToggleCheckboxOff />}
            iconOn={<ToggleCheckboxOn />} />
        </div>
      </Popover.Panel>
    </Popover>
  )
}
