import Image from 'next/image'
import { RichText } from 'prismic-reactjs'

interface Props {
  prismicContributor: any
}

export const ContributorFooter = ({ prismicContributor }: Props) => {
  const { data } = prismicContributor

  return (
    <section className='article-author'>
      <aside className='article-author__rail'>
        <figure className='author'>
          <div className='author__avatar'>
            <Image
              width={80}
              height={80}
              layout={'responsive'}
              src={data?.profile_photo.url || '/assets/img/avatar-placeholder@2x.jpg'}
              alt={data?.profile_photo.alt} />
          </div>
          <figcaption className='author__byline'>
            <span className='author__by'>by</span>
            <address className='author__name'>{RichText.asText(data?.name)  ?? 'American Council on Exercise'}</address>
            <span className='author__role'>{RichText.asText(data?.job_title)}</span>
          </figcaption>
        </figure>
      </aside>
      <section className='article-author__content'>
        {data?.bio_text && <RichText render={data?.bio_text} />}
      </section>
    </section>
  )
}
