interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const CloseIcon = ({ width = 24, height = 24 , classNames = 'icon' }: Props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      className={classNames}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1396 -109)">
          <g transform="translate(1396 109)">
            <path
              fill="currentColor"
              d="M23.645 21.94c.471.466.473 1.226.007 1.696l-.007.007a1.2 1.2 0 01-1.696.007l-.007-.007L12 13.689l-9.943 9.954a1.2 1.2 0 01-1.695.007l-.008-.007a1.2 1.2 0 01-.007-1.696l.007-.007 9.955-9.942L.354 2.056A1.204 1.204 0 012.057.353L12 10.307 21.942.353a1.204 1.204 0 011.703 1.703l-9.954 9.942 9.954 9.942z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
