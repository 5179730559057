import { Timestamp } from 'firebase/firestore'
import { useEffect } from 'react'
import { useUserProfileState, useUserTopicsDepreciator } from '../../lib/state'

export const UserTopicsDepreciator = () => {
  const [userProfile, setUserProfile] = useUserProfileState()
  const depreciate = useUserTopicsDepreciator()

  useEffect(() => {
    if (
      userProfile && (
        !userProfile.depreciated ||
        userProfile.depreciated.toMillis() < Timestamp.now().toMillis() - 3600000 * 24 * 3
      )
    ) {
      setUserProfile({ ...userProfile, depreciated: Timestamp.now() })
      depreciate()
    }
  }, [userProfile])

  return null
}
