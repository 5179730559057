interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const CrosshairIcon = ({
  width = 88,
  height = 88,
  classNames = 'icon',
}: Props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 88 88'
      className={classNames}
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fill='currentColor' transform='translate(-299 -1160)'>
          <g transform='translate(299 1160)'>
            <path d='M45.872 69.14V45.872H69.14c-.917 12.414-10.854 22.351-23.268 23.268M18.86 45.872h23.268V69.14c-12.414-.917-22.351-10.854-23.268-23.268M42.128 18.86v23.268H18.86c.917-12.414 10.854-22.351 23.268-23.268M69.14 42.128H45.872V18.86c12.414.917 22.351 10.854 23.268 23.268m16.988 0H72.894c-.929-14.48-12.542-26.093-27.022-27.022V1.872a1.872 1.872 0 00-3.744 0v13.234c-14.48.929-26.093 12.542-27.022 27.022H1.872a1.872 1.872 0 000 3.744h13.234c.929 14.48 12.542 26.093 27.022 27.022v13.234a1.872 1.872 0 003.744 0V72.894c14.48-.929 26.093-12.542 27.022-27.022h13.234a1.872 1.872 0 000-3.744'></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
