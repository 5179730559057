import { useEffect, useState } from 'react'
import { useOnboarding, useShouldOnboard } from '../../lib/state'
import { CompletedOnboardingModal, OnboardingModal } from '../modals'

export const UserProfileObserver = () => {
  const [shouldOnboard] = useShouldOnboard()
  const canOnboard = useOnboarding()
  const [onboardingIsOpen, setOnboardingIsOpen] = useState(false)
  const [completedIsOpen, setCompletedIsOpen] = useState(false)

  useEffect(() => {
    if (!onboardingIsOpen && shouldOnboard === true)
      setOnboardingIsOpen(true)
  }, [shouldOnboard])

  return !canOnboard ? null : (
    <>
      <OnboardingModal
        open={onboardingIsOpen}
        onClose={() => setOnboardingIsOpen(false)}
        onFinish={() => {
          setOnboardingIsOpen(false)
          setCompletedIsOpen(true)
        }} />
      <CompletedOnboardingModal
        open={completedIsOpen}
        onClose={() => setCompletedIsOpen(false)} />
    </>
  )
}
