interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const VideoContentIcon = ({ width = 24, height = 23 , classNames = 'icon' }: Props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 23"
      className={classNames}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-127 -818)">
          <g fill="currentColor" transform="translate(127 818)">
            <path d="M0 9.4799032L0 13.9911333 1.60001067 13.9911333 1.60001067 11.0799139 16.0001067 11.0799139 16.0001067 20.6799779 1.60001067 20.6799779 1.60001067 19.1399676 0 19.1399676 0 22.2799885 17.6001173 22.2799885 17.6001173 9.4799032z"></path>
            <path d="M16 13.964v3.831l8 3.91V10.054l-8 3.91zm1.6 2.832v-1.832l4.8-2.346v6.522l-4.8-2.344z"></path>
            <g>
              <path d="M10.4 15.08a.8.8 0 110 1.6.8.8 0 010-1.6"></path>
              <path d="M7.2 15.08a.8.8 0 110 1.6.8.8 0 010-1.6"></path>
              <path d="M4 1.6a2.4 2.4 0 100 4.8 2.4 2.4 0 000-4.8M4 8a4 4 0 110-8 4 4 0 010 8"></path>
              <path d="M12.8 1.6a2.4 2.4 0 100 4.8 2.4 2.4 0 000-4.8m0 6.4a4 4 0 11.001-8 4 4 0 010 8"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
