import { prettyDuration2 } from '../../lib/util'
import { CardSlider } from '../cards'
import { Loading } from '../common'
import { LayoutRow } from '../layout'

interface Props {
  hits: any[]
  loading: boolean
}

export const MyTasks = ({ hits, loading }: Props) => {
  const minutes = hits?.reduce((sum, hit) => {
    sum += hit.consumption_time
    return sum
  }, 0)

  const NoData = () => (
    <div className='my__topics'>
      <p>You do not have any active Challenges or Recommendations.</p>
    </div>
  )

  return (
    <article className='my'>
      <LayoutRow classNames='bg-mtn bg-mtn bg-mtn--valign-middle'>
        <section className='my__wrap'>
          <header data-tour='my-tasks' className='my__header'>
            <span className='my__header-content'>
              <span className='my__header-content-headline'>
                <h2>Things to do now</h2>
              </span>
              <p>Start today&apos;s journey with the most relevant videos, articles and Challenge tasks recommended for you.</p>
            </span>
          </header>
          {loading || !hits
            ? (
              <div className='loading loading--page'>
                <Loading />
              </div>
            )
            : hits?.length
              ? (
                <>
                  <div className='my__content-header'>
                    <span className="my__duration">{prettyDuration2(minutes)} total</span>
                  </div>
                  <CardSlider cards={hits} theme={'light'} from='recommendation' />
                </>
              )
              : <NoData />
          }
        </section>
      </LayoutRow>
    </article>
  )
}
