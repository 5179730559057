import { Menu, Transition } from '@headlessui/react'
import Image from 'next/image'
import firebase from '../../lib/firebase'
import { User } from '../../lib/models'
import { UserProfile, useTourState, useUserContentsEnrolled, useUserContentsPinned } from '../../lib/state'
import { AceIcon, MobileMenuCloseIcon, MobileMenuIcon, SettingsIcon } from '../icons'
import { MenuLink } from './menu-link'

interface Props {
  user: User
  userProfile: UserProfile
}

export const UserMenu = ({ user, userProfile }: Props) => {
  const [enrolledIds] = useUserContentsEnrolled()
  const [pinnedIds] = useUserContentsPinned()
  const [tourState, setTourState] = useTourState()

  return (
    <div className='nav__user-menu-wrap'>
      <Menu>
        {({ open }) => {
          document.body.className = document.body.className.replace(' user-menu-open', '')
          if (open) document.body.className += ' user-menu-open'
          return (
            <>
              <Menu.Button className='nav__user-menu-button'>
                <span className='nav__user-menu-button__desktop' aria-hidden='true' >
                  <span className='nav__user-name'>
                    {userProfile?.name?.first} {userProfile?.name?.last}
                  </span>
                  <Image className='nav__user-avatar' src={user.photoURL} alt={user.displayName} width={40} height={40} />
                </span>
                <span className='nav__user-menu-button__mobile' aria-hidden='true'>
                  <MobileMenuIcon classNames='icon-menu' />
                  <MobileMenuCloseIcon classNames='icon-close' />
                </span>
              </Menu.Button>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Menu.Items className={`nav__user-menu ${open ? 'nav__user-menu--open' : ''}`}>
                  <Menu.Item>
                    {({ active }) => (
                      <MenuLink href='/challenges' className={`nav__user-menu-item nav__user-menu-item--challenges${active ? ' nav__user-menu-item--focus' : ''}`}>
                        <span className='nav__user-menu-item-label'>My Challenges</span>
                        {enrolledIds?.length > 0 && (
                          <span className='nav__user-menu-item-count'>{enrolledIds?.length}</span>
                        )}
                      </MenuLink>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <MenuLink href='/pins' className={`nav__user-menu-item nav__user-menu-item--pins${active ? ' nav__user-menu-item--focus' : ''}`}>
                        <span className='nav__user-menu-item-label'>My Pins</span>
                        {pinnedIds?.length > 0 && (
                          <span className='nav__user-menu-item-count'>{pinnedIds?.length}</span>
                        )}
                      </MenuLink>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <MenuLink href='/profile' className={`nav__user-menu-item ${active && 'nav__user-menu-item--focus'}`}>
                        <span className='nav__user-menu-item-label'>My Compass Profile</span>
                        <span className='nav__user-menu-item-icon'>
                          <SettingsIcon />
                        </span>
                      </MenuLink>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <MenuLink
                        onClick={() => setTourState({ run: true, stepIndex: 0 })}
                        href='/my-compass'
                        className={`nav__user-menu-item ${active && 'nav__user-menu-item--focus'}`}>
                        <span className='nav__user-menu-item-label'>Help</span>
                        <span className='nav__user-menu-item-icon'>
                          <AceIcon />
                        </span>
                      </MenuLink>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <MenuLink href='/' onClick={() => firebase.signOut()} className={`nav__user-menu-item ${active && 'nav__user-menu-item--focus'}`}>
                        <span className='nav__user-menu-item-label'>Sign Out</span>
                      </MenuLink>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </>
          )
        }}
      </Menu>
    </div>
  )
}
