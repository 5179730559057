import { Switch } from '@headlessui/react'
import { ScoreAction, useAnalytics, useUserContentState, useUserTopicCallback } from '../../lib/state'
import { CheckboxIcon } from '../icons'

interface Props {
  contentId: string
  taskId: string
  topicIds: string[]
  className?: string
}

export const CompletionButton = ({ contentId, taskId, topicIds, className = '' }: Props) => {
  const [userContent, setUserContent] = useUserContentState(contentId)
  const userTopicCallback = useUserTopicCallback()
  const analytics = useAnalytics()

  const complete = userContent?.completions?.includes(taskId)

  const setComplete = (value: boolean) => {
    const completions = value ?
      [...userContent.completions, taskId] :
      userContent.completions.filter(id => id !== taskId)
    setUserContent({ ...userContent, completions })
    if (value) userTopicCallback({ action: ScoreAction.CompleteTask }, topicIds)
    analytics('link', {
      'event_type': value ? 'complete_content' : 'uncomplete_content',
      'challenge_id': contentId,
      'content_id': taskId,
    })
  }

  return (
    <Switch
      checked={complete}
      onChange={setComplete}
      className={`${className} completion ${complete && 'completion--complete'}`}>
      <span className='sr-only'>Mark complete</span>
      <CheckboxIcon />
      <span className='completion__text'>{complete ? 'Complete!' : 'Mark Complete'}</span>
    </Switch>
  )
}
