interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const PinOutlineIcon = ({ width = 25, height = 25 , classNames = 'icon' }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25 25"
      className={classNames}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="currentColor" transform="translate(-1268 -778)">
          <path
            d="M1271.5 794.346a2.703 2.703 0 012.546-1.8c.495 0 .9-.405.9-.9l.9-11.7h5.4l.9 11.7c0 .495.405.9.9.9 1.17 0 2.178.756 2.547 1.8H1271.5zm7.046 12.6c.495 0 .9-.405.9-.9v-9.9h7.2c.495 0 .9-.405.9-.9a4.508 4.508 0 00-3.6-4.41l-.9-10.89h.9c.495 0 .9-.405.9-.9s-.405-.9-.9-.9h-10.8c-.495 0-.9.405-.9.9s.405.9.9.9h.9l-.9 10.89a4.5 4.5 0 00-3.6 4.41c0 .495.405.9.9.9h7.2v9.9c0 .495.405.9.9.9z"
            transform="rotate(45 1278.546 792.546)"
          ></path>
        </g>
      </g>
    </svg>
  );
}
