import { useUserContentState } from '../../lib/state'
import { prettyDistance } from '../../lib/util'

interface Props {
  contentId: string
}

export const ViewedAgo = ({ contentId }: Props) => {
  const [userContent] = useUserContentState(contentId)

  return !userContent || !userContent.viewed ? null : (
    <span className='article-preheader__details article-preheader__details--ago'>
      Viewed {prettyDistance(userContent.viewed)} ago.
    </span>
  )
}
