import { Portal } from '@headlessui/react'
import { useEffect } from 'react'
import { motion } from 'framer-motion'

interface Props {
  open: boolean
  onClose: () => void
  seconds?: number
  children?: any
}

export const Toast = ({ open, onClose, seconds = 4, children }: Props) => {
  useEffect(() => {
    if (open) setTimeout(onClose, seconds * 1000)
  }, [open, onClose, seconds])

  return open && (
    <Portal>
      <motion.div
        className="toast"
        initial={{ y: '100vh', x: '-50%' }}
        animate={{ y: '-15vh' }}
        transition={{ delay: 0, duration: 0.2, type: 'spring', stiffness: 75 }}
      >
        <div className="toast__inner">
          {children}
        </div>
      </motion.div>
    </Portal>
  )
}
