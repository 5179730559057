interface Props {
  children?: any,
}

export const Feedback = ({ children }: Props) => {
  return (
    <section className='feedback'>
      <div className='feedback__inner'>
        <header className='feedback__header'>
          <h4 className='feedback__header-title'>Do you want to see more like this?</h4>
        </header>
        <div className='feedback__body'>
          <p className='feedback__body-label'>Compass gets smarter when you react.</p>
          <div className='feedback__body-actions'>
            {children}
          </div>
        </div>
      </div>
    </section>
  )
}
