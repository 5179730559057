interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const AceIcon = ({ width = 25, height = 23 , classNames = 'icon' }: Props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 25 23"
      className={classNames}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-239 -151)">
          <g fill="currentColor" transform="translate(239 151)">
            <path d="M2.996 8.978L.062 12.576a.224.224 0 00-.062.156c0 .128.107.231.239.231l11.384-.003 2.108.003a2.097 2.097 0 001.42-.596c.366-.361.576-.84.588-1.335l.002-.084V7.694l-4.118-3.99V8.98l-8.627-.002z"></path>
            <g transform="translate(11.111)">
              <path d="M13.294 9.9l-.005-.004L3.751.332a1.12 1.12 0 00-.802-.33L2.916 0H1.13C.507 0 0 .508 0 1.133v1.166l2.128 2.137c.009.009.016.02.025.03l6.861 6.88-6.005 6.017.545 4.635a.235.235 0 00.401.155l7.909-7.933 1.467-1.466c.36-.38.553-.865.558-1.419 0-.534-.212-1.044-.595-1.436"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
