import { Dialog } from '@headlessui/react'
import { motion, AnimatePresence } from 'framer-motion'
import { CloseIcon } from '../icons'

interface Props {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

export const LeaveChallengeModal = ({ open, onClose, onConfirm }: Props) => {

  const backdrop = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 }
  }

  const scaleInUpScaleOutDown = {
    hidden: { scale: 0 },
    visible: { scale: 1 },
    exit: {
      scale: 0
    }
  }

  const popUpInSlideDownOut = {
    hidden: {
      y: '100%',
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
    },
    exit: {
      y: '-100%',
      opacity: 0,
    },
  }


  return (
    <AnimatePresence exitBeforeEnter>
      {open && (
        <Dialog
          static
          className='modal'
          as={motion.div}
          variants={backdrop}
          open={open}
          onClose={onClose}
          initial='hidden'
          animate='visible'
          exit='hidden'
          transition={{ delay: 0, type: 'tween' }}
        >
          <motion.div className='modal__inner'>
            <Dialog.Overlay className='modal__overlay' />
            <motion.div
              className='modal__body'
              variants={popUpInSlideDownOut}
              initial='hidden'
              animate='visible'
              exit='hidden'
              transition={{ duration: 0.5 }}
            >
              <button className='modal__x-btn' type='button' onClick={onClose}><CloseIcon width={24} height={24} /></button>
              <Dialog.Title className={'modal__title'}>Quit this challenge?</Dialog.Title>
              <Dialog.Description className={'modal__desc'}>
                Compass will forget any progress you&apos;ve made against the tasks in this Challenge.
              </Dialog.Description>
              <button onClick={onConfirm} className='modal__confirm-btn'>Quit challenge now</button>

              <div className="modal__footer">
                <button className='modal__cancel-btn' onClick={onClose}>Cancel</button>
              </div>
            </motion.div>
          </motion.div>
        </Dialog>
      )}
    </AnimatePresence>
  )
}
