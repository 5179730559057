import { motion, useMotionValue } from 'framer-motion'

interface Props {
  totalTasks: number;
  completedTasks: number;
  width?: number;
  height?: number

}

export const CardProgressRing = ({ totalTasks, completedTasks, width = 54, height = 54 }: Props) => {

  const progress = completedTasks * 100 / totalTasks;
  const radius = 22;
  const circumference = radius * 2 * Math.PI;
  const strokeDashoffset = circumference - progress / 100 * circumference;
  const progressDegrees = progress / 100 * 360;

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 54 54'
      className='progress-ring'
    >
      <g
        fill='none'
        fillRule='evenodd'
        stroke='none'
        strokeWidth='1'
      >
        <g transform='translate(-10 -427)'>
          <g id='progress-elements' transform='translate(10 427)'>
            <circle
              id='circle-bottom-track'
              className='card-progress-ring__circle-group-track'
              cx='27'
              cy='27'
              r={radius}
              stroke='#DADFE1'
              strokeOpacity='0.5'
              strokeWidth='4'
            ></circle>
            <circle
              id='circle-top-progress'
              className='card-progress-ring__circle-group-progress'
              cx='27'
              cy='27'
              r={radius}
              strokeDasharray={circumference + ' ' + circumference}
              strokeDashoffset={strokeDashoffset}
              stroke='#43BEAC'
              strokeWidth='4'
            ></circle>
            <g
              id='indicator-dot-group'
              className='progress-ring__indicator-dot-rotator-box'
              transform={`rotate(${progressDegrees})`}
            >
              <path
                id='indicator-bounding-box'
                fill='#FFF'
                fillOpacity='0'
                d='M0 0H54V54H0z'
              ></path>
              <circle
                id='indicator-dot'
                cx='26.5'
                cy='4.5'
                r='4.5'
                fill='#43BEAC'
              ></circle>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
