import { certifications, employmentTypes, experiences, professionalInterests, topics } from './fields'

const fieldNames = [
  ...certifications.values,
  ...experiences.values,
  ...professionalInterests.values,
  ...employmentTypes.values,
].reduce((map, { id, name }) => {
  map[id] = name
  return map
}, {})

const topicNames = topics.groups.reduce((map, { values }) => {
  values.forEach(({ id, name }) => {
    map[id] = name
  })
  return map
}, {})

interface ContentName {
  title: string
  contributor: string
  duration: number
  type: string
}

const contentNames: { [id: string]: ContentName } = {}

export const registerContent = (contentId: string, name: ContentName): void => {
  contentNames[contentId] = name
}

export const nameField = (fieldId: string): string => {
  return fieldNames[fieldId] ?? ''
}

export const nameFields = (fieldIds: string[]): string[] => {
  return fieldIds?.map(fieldId => fieldNames[fieldId] ?? '')
}

export const nameTopic = (topicId: string): string => {
  return topicNames[topicId] ?? ''
}

export const nameTopics = (topicIds: string[]): string[] => {
  return topicIds?.map(topicId => topicNames[topicId] ?? '')
}

export const nameContent = (contentId: string): string => {
  return contentNames[contentId]?.title ?? ''
}

export const nameContents = (contentIds: string[]): string[] => {
  return contentIds?.map(contentId => contentNames[contentId]?.title ?? '')
}

export const nameContentContributor = (contentId: string): string => {
  return contentNames[contentId]?.contributor ?? ''
}

export const nameContentContributors = (contentIds: string[]): string[] => {
  return contentIds?.map(contentId => contentNames[contentId]?.contributor ?? '')
}

export const nameContentDuration = (contentId: string): number => {
  return contentNames[contentId]?.duration ?? 0
}

export const nameContentDurations = (contentIds: string[]): number[] => {
  return contentIds?.map(contentId => contentNames[contentId]?.duration ?? 0)
}

export const nameContentType = (contentId: string): string => {
  return contentNames[contentId]?.type ?? ''
}

export const nameContentTypes = (contentIds: string[]): string[] => {
  return contentIds?.map(contentId => contentNames[contentId]?.type ?? '')
}
