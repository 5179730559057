interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const ChevronDownIcon = ({ width = 16, height = 10 , classNames = 'icon' }: Props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 10"
      className={classNames}
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fill='currentColor' transform='translate(-1205 -646)'>
          <path d='M1219.69408 646L1212.99988 652.693969 1206.30638 646 1205 647.306146 1212.34693 654.653073 1212.35178 654.647993 1213.00496 655.300951 1221 647.306146z'></path>
        </g>
      </g>
    </svg>
  );
}
