import { useBestRelatedChallengeInfo, useUserContentState } from '../../lib/state'
import { CardProgressRing } from './card-progress-ring'

interface Props {
  contentId: string
}

export const CardChallengeRelated = ({ contentId }: Props) => {
  const [challengeInfo] = useBestRelatedChallengeInfo(contentId)
  const [userContent] = useUserContentState(challengeInfo?.id)

  return !challengeInfo ? null : (
    <span className='card-related'>
      {userContent?.enrolled && (
        <span className='card-related__progress'>
          <CardProgressRing
            totalTasks={challengeInfo.taskIds?.length ?? 0}
            completedTasks={userContent.completions?.length ?? 0}
          />
        </span>
      )}
      <span className='card-related__info'>
        {challengeInfo.id !== contentId && (
          <span className='card-related__info-part'>
            Part of <span className='card-related__info-part-title'>{challengeInfo.title}</span>
          </span>
        )}
        {userContent?.enrolled && (
          <span className='card-related__info-progress'>{userContent.completions?.length ?? 0} of {challengeInfo.taskIds?.length ?? 0} Completed</span>
        )}
      </span>
    </span>
  )
}
