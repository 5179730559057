interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const AceLogotype = ({ width = 120, height = 29 , classNames = 'icon' }: Props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 120 29"
      className={classNames}
    >
      <defs>
        <path
          id="path-2"
          d="M0 0L31.8584071 0 31.8584071 28.6725664 0 28.6725664z"
        ></path>
        <path
          id="path-4"
          d="M0 0L20.1769912 0 20.1769912 10.619469 0 10.619469z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-30 -74)">
          <g transform="translate(30 74)">
            <path
              fill="currentColor"
              d="M46.135 5.329c3 0 5.79 1.501 7.463 4.02l.083.123 3.652-3.986.012-.015-.061-.076C54.55 1.965 50.489 0 46.135 0c-7.873 0-14.277 6.43-14.277 14.336 0 7.906 6.404 14.337 14.277 14.337 4.354 0 8.416-1.966 11.149-5.396l.061-.078-3.647-3.98-.017-.018-.083.121a8.951 8.951 0 01-7.463 4.022c-4.946 0-8.97-4.04-8.97-9.008 0-4.966 4.024-9.007 8.97-9.007"
            ></path>
            <g>
              <mask id="mask-3" fill="#fff">
                <use xlinkHref="#path-2"></use>
              </mask>
              <path
                fill="currentColor"
                d="M16.676 7.728l2.954 6.606.004.015h-7.413L18.581.12A1.582 1.582 0 0018.002 0h-4.146c-.643 0-1.214.371-1.459.947L.073 28.511 0 28.673h5.817l4.076-9.117 12.07-.003 4.046 9.05.032.07h5.817L19.586 1.222l-2.91 6.506z"
                mask="url(#mask-3)"
              ></path>
            </g>
            <g transform="translate(61.593)">
              <mask id="mask-5" fill="#fff">
                <use xlinkHref="#path-4"></use>
              </mask>
              <path
                fill="currentColor"
                d="M2.514 0C1.126 0 0 1.187 0 2.645v7.973h5.053V5.32h15.124V0H2.514z"
                mask="url(#mask-5)"
              ></path>
            </g>
            <path
              fill="currentColor"
              d="M66.646 16.906v-.002h8.827l3.598-4.717a.308.308 0 00.077-.202.299.299 0 00-.293-.304l-12.21.003v-.003h-5.052v14.392c0 1.435 1.126 2.6 2.514 2.6H81.77v-5.225H66.646v-6.542z"
            ></path>
            <path
              fill="currentColor"
              d="M90.92 11.964l-3.76 4.538a.282.282 0 00-.08.197.3.3 0 00.306.292l14.593-.004 2.702.004a2.711 2.711 0 001.818-.753c.47-.455.738-1.059.755-1.684l.003-.105-.003-4.105-5.275-5.034v6.656l-11.06-.002z"
            ></path>
            <path
              fill="currentColor"
              d="M119.226 12.772l-.005-.004-12.4-12.342a1.461 1.461 0 00-1.04-.424L105.737 0h-2.322c-.81 0-1.468.655-1.468 1.462v1.502l2.766 2.758c.012.012.02.027.032.039l8.92 8.877-7.808 7.766.71 5.98a.306.306 0 00.522.199l10.28-10.234 1.905-1.893c.469-.49.719-1.117.726-1.832 0-.689-.274-1.347-.774-1.852"
            ></path>
            <path
              fill="currentColor"
              d="M111.943 2.022h-.237v-.718h.3c.156 0 .33.04.33.342 0 .349-.177.376-.393.376zm.234.214c.24-.043.365-.241.365-.573 0-.403-.163-.601-.499-.601h-.539v2.124h.202v-.923h.25l.007.01.389.913h.214l-.415-.946.026-.004z"
            ></path>
            <path
              fill="currentColor"
              d="M112.037 2.978c-.763 0-1.36-.601-1.36-1.384 0-.782.597-1.386 1.36-1.386.762 0 1.357.604 1.357 1.386 0 .783-.595 1.384-1.357 1.384m0-2.978c-.893 0-1.595.7-1.595 1.594 0 .895.702 1.592 1.595 1.592.89 0 1.591-.697 1.591-1.592 0-.894-.701-1.594-1.591-1.594"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
