import { useEffect, useState } from 'react'
import { SearchQuery, useAnalytics, useSearchResponse } from '../../lib/state'
import { Card } from '../cards'
import { CardChallengeRelated } from '../cards/card-challenge-related'
import { Loading } from '../common'

const pages = (length: number) => Array(length + 1).fill(null).map((_, index) => index)

interface Props {
  searchQuery: SearchQuery
}

const CardPage = ({ searchQuery }: Props) => {
  const [response, loading, error] = useSearchResponse(searchQuery)
  const analytics = useAnalytics()

  return (
    <>
      {response?.hits.map(hit => (
        <Card
          data={hit}
          key={hit.objectID}
          onClick={() => {
            analytics('link', {
              'event_type': 'select_content',
              'content_id': hit.objectID,
              'topic_ids': hit.topic_ids,
            })
          }}>
          <CardChallengeRelated contentId={hit.objectID} />
        </Card>
      ))}
    </>
  )
}

export const PaginatedCards = ({ searchQuery }: Props) => {
  const [response, loading, error] = useSearchResponse(searchQuery)
  const [currentPage, setCurrentPage] = useState(0)
  const canLoadMore = response && response.nbPages > currentPage + 1

  useEffect(() => {
    setCurrentPage(0)
  }, [searchQuery])

  return (
    <>
      <div className='grid'>
        {pages(currentPage).map(page => (
          <CardPage key={page} searchQuery={{ ...searchQuery, page }} />
        ))}
      </div>
      {loading && (
        <div className='loading loading--page'>
          <Loading />
        </div>
      )}
      {canLoadMore && (
        <div className='show-more'>
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            className='show-more__button'
          >
            Show more
          </button>
        </div>
      )}
    </>
  )
}
