import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useAuth, useUser } from '../../lib/state'
import { Loading } from '../common'
import { Takeover } from '../layout'

const publicPages = [
  '/',
  '/sign-in',
]

export const UserGuard = ({ children }) => {
  const router = useRouter()
  const auth = useAuth()
  const user = useUser()

  useEffect(() => {
    if (auth === false && !publicPages.includes(router.pathname)) {
      localStorage.setItem('ace-compass-auth-route', router.asPath)
    }
  }, [auth])

  if (user || publicPages.includes(router.pathname)) return <>{children}</>

  return (
    <Takeover>
      <div className='login'>
        {auth === null ? (
          <Loading />
        ) : (
          <>
            <h2 className='login__headline'>You are not signed into ACE<sup>®</sup> Pro Compass</h2>
            <p className='login__message'>
              To access, you must hold a current ACE certification.
            </p>
            <a className='login__button' href={process.env.NEXT_PUBLIC_AUTH_URL}>Sign in</a>
            <p className='login__help'>
              Need help?
              Contact us and we are happy to assist.
              Our team is available by phone at 1 (888) 825-3636 Monday-Friday 7am - 4pm PST.
              Or you can also email questions to <a href='mailto:support@acefitness.org' target='_blank' rel='noreferrer'>support@acefitness.org</a>.
            </p>
          </>
        )}
      </div>
    </Takeover>
  )
}
