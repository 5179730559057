import { professionalInterests } from '../../lib/fields'
import { useAnalytics, useAnalyticsPageView, useUserProfileState } from '../../lib/state'
import { snakeCase } from '../../lib/util'
import { FieldValuesSelect } from '../forms'
import { CheckmarkIcon } from '../icons'

export const ProfessionalInterestPanel = () => {
  const [userProfile, setUserProfile] = useUserProfileState()

  const analytics = useAnalytics()
  useAnalyticsPageView({ title: 'Professional Interest', type: 'Onboarding' })

  return (
    <>
      <header className='onboard__panel-header'>
        <h2>Professional Interest</h2>
        <p>At what level do you want to pursue your career in health &amp; fitness? Whether it&apos;s full-time or a passion project on the side, there are plenty of directions you can take your career.</p>
      </header>
      <section className='onboard__panel-content'>
        <FieldValuesSelect
          values={professionalInterests.values}
          selected={userProfile?.professionalInterest}
          onSelect={professionalInterest => {
            setUserProfile({ ...userProfile, professionalInterest })
            analytics('link', {
              'event_type': 'onboarding',
              [snakeCase('professionalInterest')]: professionalInterest,
            })
          }}
          baseClassName={'onboard-options-grid'}
          iconOn={<CheckmarkIcon />}
          iconOff={<CheckmarkIcon />} />
      </section>
    </>
  )
}
