import { topics } from '../../lib/fields'
import { ScoreAction, useAnalytics, useUserTopicCallback, useUserTopicsExplicit } from '../../lib/state'
import { FieldValuesMultipleSelect } from '../forms'
import { CheckmarkIcon } from '../icons'

interface Props {
  topicIds: string[]
}

export const TopicsPanel = ({ topicIds }: Props) => {
  const [userTopicsExplicit] = useUserTopicsExplicit()
  const userTopicCallback = useUserTopicCallback()
  const analytics = useAnalytics()

  const sentimentTopics = topics.groups
    .flatMap(group => group.values)
    .filter(topic => topicIds.includes(topic.id))

  return (
    <div className='onboard__panel-inner'>
      <div className='onboard__panel-inner-content'>
        <header className='onboard__panel-header'>
          <h2>Add some topics to your life?</h2>
          <p>
            It seems like these topics could be a good fit.<br />
            Select the ones that interest you.
          </p>
        </header>
        <FieldValuesMultipleSelect
          values={sentimentTopics}
          selected={userTopicsExplicit}
          onSelect={ids => {
            const newIds = ids.filter(id => !userTopicsExplicit.includes(id))
            const oldIds = userTopicsExplicit.filter(id => !ids.includes(id))
            if (newIds.length) {
              userTopicCallback({ action: ScoreAction.LikeTopic, explicit: true }, newIds)
              analytics('link', {
                'event_type': 'sentiment_topics',
                'sentiment': 'like',
                'topic_ids': newIds,
              })
            }
            if (oldIds.length) {
              userTopicCallback({ action: ScoreAction.UnlikeTopic, explicit: false }, oldIds)
              analytics('link', {
                'event_type': 'sentiment_topics',
                'sentiment': 'dislike',
                'topic_ids': oldIds,
              })
            }
          }}
          baseClassName={'onboard-options-grid'}
          iconOn={<CheckmarkIcon />}
          iconOff={<CheckmarkIcon />} />
      </div>
    </div>
  )
}
