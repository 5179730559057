import { Timestamp } from 'firebase/firestore'
import { DocumentType } from './prismic'

export const prettyDuration = (minutes: number): string => {
  if (minutes < 60 || minutes === 90)
    return `${minutes} min`
  else {
    const hours = Math.round(minutes / 60)
    return `${hours} hr`
  }
}

export const prettyDuration2 = (minutes: number): string => {
  return [
    { label: 'hr', value: Math.floor(minutes / 60) },
    { label: 'min', value: Math.floor(minutes % 60) },
  ]
    .filter(({ value }) => value > 0)
    .map(({ label, value }) => `${value} ${label}`)
    .join(' ')
}

export const prettyDistance = (time: Timestamp): string => {
  const minutes = Math.floor((Timestamp.now().toMillis() - time.toMillis()) / 1000 / 60)
  if (minutes < 1)
    return '< 1 min'
  if (minutes < 60)
    return pluralMaybe(minutes, 'min')
  const hours = Math.floor((Timestamp.now().toMillis() - time.toMillis()) / 1000 / 60 / 60)
  if (hours < 24)
    return pluralMaybe(hours, 'hr')
  const days = Math.floor((Timestamp.now().toMillis() - time.toMillis()) / 1000 / 60 / 60 / 24)
  return pluralMaybe(days, 'day')
}

export const pluralMaybe = (count: number, word: string): string => {
  return `${count} ${word}${count === 1 ? '' : 's'}`
}

export const snakeCase = (text: string): string => {
  return text.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
}

export const aceGroupsToFieldIds = (groups: string[]): string[] => {
  const toId = (group: string): string => {
    switch (group) {
      case 'ace_certified_personal_trainer': return 'abgo2b'
      case 'ace_certified_group_fitness_instructor': return '4n0p1x'
      case 'ace_certified_health_coach': return 'rhzo56'
      case 'ace_certified_medical_exercise_specialist': return '06hab2'
      default: return null
    }
  }
  return groups?.map(group => toId(group)).filter(id => !!id) ?? []
}

export const contentPredicate = (type: DocumentType): string => {
  switch (type) {
    default:
    case DocumentType.Article: return 'read'
    case DocumentType.Audio: return 'listen'
    case DocumentType.Challenge: return 'challenge'
    case DocumentType.Resource: return 'resource'
    case DocumentType.Video: return 'watch'
    case DocumentType.CourseResource: return 'course'
  }
}
