import Link from 'next/link'
import { CardSlider } from '../cards'
import { Loading } from '../common'
import { ArrowLeftIcon } from '../icons'
import { LayoutRow } from '../layout'

interface Props {
  hits: any[]
  loading: boolean
}

export const MyRecommendations = ({ hits, loading }: Props) => {

  const NoData = () => (
    <div className='my__topics'>
      <p>You do not have any Recommendations.</p>
    </div>
  )

  return (
    <article className='my'>
      <LayoutRow>
        <section className='my__wrap'>
          <header className='my__header'>
            <span className='my__header-content'>
              <span className='my__header-content-headline'>
                <h2>Recommended for You</h2>
                <Link href='/explore'>
                  <a className='arrow-link'>Explore More<ArrowLeftIcon /></a>
                </Link>
              </span>
              <p>Based on your interests and feedback, we think you might like to add these to your journey. Improve your recommendations by giving feedback on content and Challenges.</p>
            </span>
          </header>
          {loading || !hits
            ? (
              <div className='loading loading--page'>
                <Loading />
              </div>
            )
            : hits?.length
              ? <CardSlider cards={hits} theme={'light'} from='recommendation' />
              : <NoData />
          }
        </section>
      </LayoutRow>
    </article>
  )
}
