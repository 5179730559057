import { Dialog } from '@headlessui/react'
import { AnimatePresence, motion } from 'framer-motion'
import Link from 'next/link'
import { DocumentType } from '../../lib/prismic'
import { CloseIcon } from '../icons'

interface Props {
  open: boolean
  onClose: () => void
}

export const CompletedChallengeModal = ({ open, onClose }: Props) => {

  const backdrop = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 }
  }

  const scaleInUpScaleOutDown = {
    hidden: { scale: 0 },
    visible: { scale: 1 },
    exit: {
      scale: 0
    }
  }

  const popUpInSlideDownOut = {
    hidden: {
      y: '100%',
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
    },
    exit: {
      y: '-100%',
      opacity: 0,
    },
  }


  return (
    <AnimatePresence exitBeforeEnter>
      {open && (
        <Dialog
          static
          className='modal'
          as={motion.div}
          variants={backdrop}
          open={open}
          onClose={onClose}
          initial='hidden'
          animate='visible'
          exit='hidden'
          transition={{ delay: 0, type: 'tween' }}
        >
          <motion.div className='modal__inner'>
            <Dialog.Overlay className='modal__overlay' />
            <motion.div
              className='modal__body'
              variants={popUpInSlideDownOut}
              initial='hidden'
              animate='visible'
              exit='hidden'
              transition={{ duration: 0.5 }}
            >
              <button className='modal__x-btn' type='button' onClick={onClose}><CloseIcon width={24} height={24} /></button>
              <div className="modal__pre-title modal__pre-title--emoji">
                👏👏👏
              </div>
              <Dialog.Title className={'modal__title'}>You did it!</Dialog.Title>
              <Dialog.Description as={`div`} className={'modal__desc'}>
                <p>You took action to move your fitness professional journey forward. Now it&apos;s time to put your learnings in motion and apply the insights to your practice.</p>
                <p>Keep the momentum going by exploring new Challenges.</p>
              </Dialog.Description>
              <Link href={{
                pathname: '/explore',
                query: { y: DocumentType.Challenge, c: '1' },
              }}>
                <a className='modal__confirm-btn' onClick={onClose}>Explore Challenges</a>
              </Link>
            </motion.div>
          </motion.div>
        </Dialog>
      )}
    </AnimatePresence>
  )
}
