interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const ArrowLeftIcon = ({ width = 30, height = 22 , classNames = 'icon' }: Props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 22"
      className={classNames}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-30 -109)">
          <g transform="translate(30 94)">
            <g transform="rotate(180 15 18.092)">
              <path
                fill="currentColor"
                d="M20.194.384a1.067 1.067 0 10-1.503 1.503l7.668 7.677H1.013a1.07 1.07 0 000 2.137H26.36l-7.68 7.668a1.088 1.088 0 000 1.503 1.061 1.061 0 001.503 0l9.498-9.499a1.043 1.043 0 000-1.503L20.194.384z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
