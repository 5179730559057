interface Props {
  title: string
  children?: any
}

export const ArticleHeader = ({ title, children }: Props) => {
  return (
    <header className='article-header'>
      <h1 className='article-header__title'>{title}</h1>
      {children}
    </header>
  )
}
