import { AceLogotype, SocialFacebookIcon, SocialInstagramIcon, SocialLinkedinIcon, SocialTwitterIcon } from '../icons'

export const SiteFooter = () => {
  return (
    <div className='site-footer'>
      <div className='site-footer__branding'>
        <AceLogotype />
      </div>
      <div className='site-footer__content'>
        <span className='site-footer__content-statement'>
          <p>As the leading nonprofit exercise professional and health coach certification organization, our goal is to set the highest industry standards, elevate the careers of professionals and help people worldwide make movement their mission.</p>
        </span>
        <span className='site-footer__content-legal'>
          <p>Copyright© 2022 The American Council on Exercise. All Rights Reserved. <a href='https://www.acefitness.org/legal/terms-of-use/' target='_blank' rel='noreferrer'>Terms and conditions</a></p>
        </span>
      </div>
      <div className='site-footer__contact'>
        <div className='site-footer__contact-social'>
          <a href='https://www.facebook.com/ACEfitness' target='_blank' rel='noreferrer'>
            <SocialFacebookIcon width={29} height={29} />
          </a>
          <a href='https://www.instagram.com/acefitness/' target='_blank' rel='noreferrer'>
            <SocialInstagramIcon width={29} height={29} />
          </a>
          <a href='https://www.linkedin.com/company/american-council-on-exercise' target='_blank' rel='noreferrer'>
            <SocialLinkedinIcon width={31} height={31} />
          </a>
          <a href='https://twitter.com/acefitness' target='_blank' rel='noreferrer'>
            <SocialTwitterIcon width={31} height={25} />
          </a>
        </div>
        <div className='site-footer__contact-info'>
          <span className='email-phone'>
            <a className='email' href='mailto:support@acefitness.org' target='_blank' rel='noreferrer'>support@acefitness.org</a>
            <span className='phone'>1 (888) 825-3636</span>
          </span>
          <span className='address'>
            <span className='addr-1'>9444 Balboa Ave.</span>
            <span className='addr-1'>Suite 290</span>
            <span className='addr-2'>San Diego, CA 92123-4901</span>
          </span>
        </div>
      </div>
    </div>
  )
}
