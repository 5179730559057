interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const MtnFlagIcon = ({ width = 50, height = 52 , classNames = 'icon' }: Props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 50 52"
      className={classNames}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="currentColor" transform="translate(-76 -94)">
          <g transform="translate(30 94)">
            <path d="M76.922 0L72.77 1.642 55.235 8.544 72.77 15.45v3.585l-7.9 13.679-3.929-6.813-14.94 25.886H96L76.922 18.745V0zM66.575 8.544l6.195-2.438v4.875l-6.195-2.437zm.691 28.328l7.666-13.267 13.865 24.02H53.204l7.737-13.403 1.528 2.65 2.401 4.158 2.396-4.158z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
