import Link from 'next/link'
import { UrlObject } from 'url'
import { DocumentType, getRoute } from '../../lib/prismic'

interface Props {
  uid: string
  type: DocumentType
  from?: string
  children: any
}

export const ContentLink = ({ uid, type, from, children }: Props) => {

  const href = (): string | UrlObject => {
    const pathname = (getRoute(type) || '').replace(':uid', uid)
    if (from) return { pathname, query: { from } }
    return pathname
  }

  return (
    <Link href={href()}>
      {children}
    </Link>
  )
}
