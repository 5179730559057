import { collection, doc, getDocs, setDoc, Timestamp } from 'firebase/firestore'
import { atom, selector, selectorFamily, useRecoilCallback, useRecoilStateLoadable, useRecoilValueLoadable } from 'recoil'
import firebase from '../firebase'
import { expandLoadableState, expandLoadableValue } from '../recoil'

export interface UserContent {
  completions: string[]
  completed: Timestamp
  enrolled: Timestamp
  pinned: Timestamp
  viewed: Timestamp
  sentiment: string
}

interface PartialUserContent {
  completions?: string[]
  completed?: Timestamp
  enrolled?: Timestamp
  pinned?: Timestamp
  viewed?: Timestamp
  sentiment?: string
}

const initialUserContent: UserContent = {
  completions: [],
  completed: null,
  enrolled: null,
  pinned: null,
  viewed: null,
  sentiment: null,
}

const loadUserContents = async () => {
  const uid = await firebase.userUID
  const snapshot = await getDocs(collection(firebase.db, `users/${uid}/contents`))
  return snapshot.docs.reduce((userContents, doc) => {
    userContents[doc.id] = doc.data()
    return userContents
  }, {})
}

const saveUserContent = async (id: string, userContent: UserContent) => {
  const uid = await firebase.userUID
  await setDoc(doc(firebase.db, `users/${uid}/contents`, id), userContent, { merge: false })
}

export const userContentsState = atom<{ [id: string]: UserContent }>({
  key: 'userContents',
  default: loadUserContents(),
})

const userContentQuery = selectorFamily<UserContent, string>({
  key: 'userContentQuery',
  get: id => async ({ get }) => get(userContentsState)[id] ?? initialUserContent,
  set: id => ({ get, set }, newValue) => {
    set(userContentsState, { ...get(userContentsState), [id]: newValue })
    saveUserContent(id, newValue as UserContent)
  },
})

export const useUserContents = () => expandLoadableValue(useRecoilValueLoadable(userContentsState))

export const useUserContentState = (contentId: string) => expandLoadableState(useRecoilStateLoadable(userContentQuery(contentId)))

const userContentsPinned = selector<string[]>({
  key: 'userContentsPinned',
  get: ({ get }) => (
    Object.entries(get(userContentsState))
      .filter(([id, userContent]) => userContent?.pinned)
      .sort((a, b) => {
        return b[1].pinned.toMillis() - a[1].pinned.toMillis()
      })
      .map(([id]) => id)
  )
})

export const useUserContentsPinned = () => expandLoadableValue(useRecoilValueLoadable(userContentsPinned))

export const userContentsEnrolled = selector<string[]>({
  key: 'userContentsEnrolled',
  get: ({ get }) => Object.entries(get(userContentsState))
    .filter(([id, userContent]) => userContent?.enrolled)
    .map(([id]) => id)
})

export const useUserContentsEnrolled = () => expandLoadableValue(useRecoilValueLoadable(userContentsEnrolled))

const userContentsViewedNoSentiment = selector<string[]>({
  key: 'userContentsViewedNoSentiment',
  get: ({ get }) => (
    Object.entries(get(userContentsState))
      .filter(([id, userContent]) => userContent?.viewed && !userContent?.sentiment)
      .sort((a, b) => {
        return b[1].viewed.toMillis() - a[1].viewed.toMillis()
      })
      .slice(0, 2)
      .map(([id]) => id)
  )
})

export const useUserContentsViewedNoSentiment = () => expandLoadableValue(useRecoilValueLoadable(userContentsViewedNoSentiment))

export const userContentsCompleted = selector<string[]>({
  key: 'userContentsCompleted',
  get: ({ get }) => {
    const userContents = get(userContentsState)
    const completed = Object.entries(userContents).reduce((map, [contentId, { completions }]) => {
      completions.forEach(id => map[id] = true)
      return map
    }, {});
    return Object.keys(completed)
  }
})

export const useUserContentsCompleted = () => expandLoadableValue(useRecoilValueLoadable(userContentsCompleted))

export const userChallengesCompleted = selector<string[]>({
  key: 'userChallengesCompleted',
  get: ({ get }) => Object.entries(get(userContentsState))
    .filter(([id, userContent]) => userContent?.completed)
    .map(([id]) => id)
})

export const useUserChallengesCompleted = () => expandLoadableValue(useRecoilValueLoadable(userChallengesCompleted))

export const useUserContentCallback = () => useRecoilCallback(({ set, snapshot }) => async (changes: PartialUserContent, contentIds: string[]) => {
  const userContents = await snapshot.getPromise(userContentsState)
  const newValues = contentIds.reduce((values: { [id: string]: UserContent }, contentId) => {
    const userContent = userContents[contentId] ?? initialUserContent
    values[contentId] = {
      ...userContent,
      ...changes,
    }
    return values
  }, {})
  set(userContentsState, userContents => ({
    ...userContents,
    ...newValues
  }))
  Object.entries(newValues)
    .forEach(([id, userContent]) => saveUserContent(id, userContent))
})
