import Link from 'next/link'
import { useUserContentsPinned, useSearchResponse, useUserTopicsExplicit } from '../../lib/state'
import { CardSlider } from '../cards'
import { TopicsList } from '../explore'
import { ArrowLeftIcon } from '../icons'
import { LayoutRow } from '../layout'

interface Props {
  isMyCompass?: boolean
}

export const MyPins = ({ isMyCompass = false }: Props) => {
  const [pinnedIds] = useUserContentsPinned()
  const [pinnedContent] = useSearchResponse({ includeIds: pinnedIds })
  const [userTopicsExplicit] = useUserTopicsExplicit()
  const hasPins = pinnedIds?.length > 0 && pinnedContent?.hits
  const cards = pinnedContent?.hits
    .map(hit => hit) // copy array for sort
    .sort((a, b) => {
      return pinnedIds?.indexOf(a.objectID) - pinnedIds?.indexOf(b.objectID)
    })

  const NothingPinned = () => (
    <div className='my__topics'>
      <TopicsList topicIds={userTopicsExplicit} />
    </div>
  )

  return (
    <article className='my'>
      <LayoutRow>
        <section className='my__wrap'>
          <header className='my__header'>
            <span className='my__header-content'>
              <span className='my__header-content-headline'>
                <h2>My Pins</h2>
                {hasPins && isMyCompass ? (
                  <Link href={{ pathname: '/pins' }}>
                    <a className='arrow-link'>View All Pins<ArrowLeftIcon /></a>
                  </Link>
                ) : (
                  <Link href='/explore'>
                    <a className='arrow-link'>View All Topics<ArrowLeftIcon /></a>
                  </Link>
                )}
              </span>
              {hasPins ? (
                <p>Locate items that you&apos;ve pinned here. Pick up where you left off or revisit an old favorite.</p>
              ) : (
                <p>You have not pinned aything yet. Explore topics of interest and find things to pin for later.</p>
              )}
            </span>
          </header>
          {hasPins ? (
            <CardSlider
              cards={isMyCompass ? cards.slice(0, 3) : cards}
              theme={'light'} />
          ) :
            <NothingPinned />
          }
        </section>
      </LayoutRow>
    </article>
  )
}
