import { Timestamp } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useUserProfileState } from '../../lib/state'
import { Notify } from '../common'
import { ArrowLeftIcon } from '../icons'
import { SentimentModal } from '../modals'

export const NotificationObserver = () => {
  const [userProfile, setUserProfile] = useUserProfileState()
  const [openModal, setOpenModal] = useState(null)
  const [sentimentIsOpen, setSentimentIsOpen] = useState(false)

  useEffect(() => {
    if (
      userProfile && (
        !userProfile.sentimentalized ||
        userProfile.sentimentalized.toMillis() < Timestamp.now().toMillis() - 3600000 * 24 * 3
      )
    ) {
      setOpenModal('sentiment')
    }
  }, [userProfile])

  return (
    <>
      <Notify open={openModal === 'sentiment'} onClose={() => setOpenModal(null)}>
        <div className='notify-split'>
          <div>
            <p>Help Compass get to know you better.</p>
          </div>
          <div>
            <button className='arrow-link' onClick={() => setSentimentIsOpen(true)}>
              Tune Your Compass<ArrowLeftIcon />
            </button>
          </div>
        </div>
      </Notify>
      <SentimentModal
        open={sentimentIsOpen}
        onClose={() => {
          setUserProfile({ ...userProfile, sentimentalized: Timestamp.now() })
          setOpenModal(null)
          setSentimentIsOpen(false)
        }} />
    </>
  )
}
