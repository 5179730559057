import { atom, useRecoilValue, useSetRecoilState } from 'recoil'

export const authState = atom<boolean>({
  key: 'auth',
  default: null,
})

export const useAuth = () => useRecoilValue(authState)

export const useSetAuth = () => useSetRecoilState(authState)
