import { Timestamp } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { ScoreAction, useAnalytics, useCompletedChallenges, useUserContentCallback, useUserTopicCallback } from '../../lib/state'
import { CompletedChallengeModal } from '../modals'

export const CompletedChallengeObserver = () => {
  const [completedChallenges] = useCompletedChallenges()
  const [completedIds, setCompletedIds] = useState(undefined)
  const [isOpen, setIsOpen] = useState(false)
  const userContentCallback = useUserContentCallback()
  const userTopicCallback = useUserTopicCallback()
  const analytics = useAnalytics()

  useEffect(() => {
    const ids = completedChallenges?.map(({ objectID }) => objectID)
    setCompletedIds(ids)
    const newIds = ids && completedIds && ids.filter(id => !completedIds.includes(id))
    if (newIds && newIds[0]) {
      setIsOpen(true)
      userContentCallback({ completed: Timestamp.now() }, newIds)
      const topicIds = completedChallenges
        .filter(({ objectID }) => objectID === newIds[0])
        .flatMap(({ topic_ids }) => topic_ids)
      userTopicCallback({ action: ScoreAction.CompleteChallenge }, topicIds)
      analytics('link', {
        'event_type': 'complete_challenge',
        'challenge_id': newIds[0],
      })
    }
  }, [completedChallenges])

  return (
    <CompletedChallengeModal open={isOpen} onClose={() => setIsOpen(false)} />
  )
}
