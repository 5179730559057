import { useAnalytics } from '../../lib/state'
import { DownloadIcon, ExternalIcon } from '../icons'

interface Props {
  links: ResourceLink[]
}

export interface ResourceLink {
  label: string
  url: string
  type: 'external' | 'download'
}

export const ResourceLinks = ({ links }: Props) => {
  const analytics = useAnalytics()

  return (
    <ul className='resource-links'>
      {links.map((link, index) =>
        <li key={index}>
          <a
            href={link.url}
            target="_blank"
            className="resource-links__link"
            rel="noreferrer"
            onClick={() => {
              analytics('link', {
                'event_type': 'select_resource',
                'resource_type': link.type,
                'resource_url': link.url,
              })
            }}>
            <span className="resource-links__link-icon">
              {link.type === 'external' ? <ExternalIcon /> : <DownloadIcon />}
            </span>
            <span className="resource-links__link-label">{link.label}</span>
          </a>
        </li>
      )}
    </ul>
  )
}
