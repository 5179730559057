import { Timestamp } from 'firebase/firestore'
import Link from 'next/link'
import { useState } from 'react'
import { ScoreAction, useAnalytics, useUserContentState, useUserTopicCallback } from '../../lib/state'
import { Toast } from '../common'
import { PinningCircleIcon } from '../icons'

interface Props {
  contentId: string
  topicIds: string[]
  className: string
}

export const PinButton = ({ contentId, topicIds, className }: Props) => {
  const [userContent, setUserContent] = useUserContentState(contentId)
  const [toastIsOpen, setToastIsOpen] = useState(false)
  const userTopicCallback = useUserTopicCallback()
  const analytics = useAnalytics()

  const togglePinned = () => {
    if (!userContent) return
    const pinned = userContent.pinned ? null : Timestamp.now()
    setUserContent({ ...userContent, pinned })
    if (pinned) {
      setToastIsOpen(true)
      userTopicCallback({ action: ScoreAction.PinContent }, topicIds)
    }
    analytics('link', {
      'event_type': pinned ? 'pin_content' : 'unpin_content',
      'content_id': contentId,
      'topic_ids': topicIds,
    })
  }

  return (
    <>
      <button
        onClick={togglePinned}
        aria-label={userContent?.pinned ? 'Pin it' : 'Unpin it'}
        className={`pin-button ${className} ${userContent?.pinned && 'pin-button--pinned'}`}>
        <PinningCircleIcon />
      </button>
      <Toast open={toastIsOpen} onClose={() => setToastIsOpen(false)}>
        Pinned! This item has been saved for you in <Link href='/pins'>My Pins</Link>.
      </Toast>
    </>
  )
}
