import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { getTokenFromRefresh } from '../../lib/ace-compass'
import firebase from '../../lib/firebase'
import { useUserProfileState } from '../../lib/state'
import { Toast } from '../common'

export const ReauthObserver = () => {
  const router = useRouter()
  const [didCheck, setDidCheck] = useState(false)
  const [toastIsOpen, setToastIsOpen] = useState(false)
  const [userProfile, setUserProfile] = useUserProfileState()

  const onError = () => {
    firebase.signOut()
    setToastIsOpen(true)
    router.push('/')
  }

  useEffect(() => {
    if (userProfile && !didCheck) {
      const checkSignIn = async () => {
        if (!userProfile.refresh) return onError()
        const response = await getTokenFromRefresh(userProfile.refresh)
        const { refresh } = response || {}
        if (!refresh) return onError()
        setUserProfile({ ...userProfile, refresh })
      }
      setDidCheck(true)
      checkSignIn()
    }
  }, [userProfile])

  return (
    <Toast open={toastIsOpen} onClose={() => setToastIsOpen(false)}>
      You have been signed out. Please try to  <a href={process.env.NEXT_PUBLIC_AUTH_URL}>Sign in</a> again.
    </Toast>
  )
}
