import { topics } from '../../lib/fields'
import { useAnalyticsPageView } from '../../lib/state'
import { FieldValuesMultipleSelect } from '../forms'
import { CheckmarkIcon } from '../icons'

const topicIds = {
  'siargd': true,
  '7f0517': true,
  '32zkvu': true,
  'jfx53l': true,
  '8flqk0': true,
  'qamyrc': true,
  'cus0xy': true,
}

const onboardingTopics = topics.groups
  .flatMap(group => group.values)
  .filter(topic => topicIds[topic.id])

interface Props {
  selectedIds: string[]
  onChange: (ids: string[]) => void
}

export const TopicsPanel = ({ selectedIds, onChange }: Props) => {
  useAnalyticsPageView({ title: 'Topics of Interest', type: 'Onboarding' })

  return (
    <>
      <header className='onboard__panel-header'>
        <h2>Topics of Interest</h2>
        <p>What do you want to learn about the most from ACE® Pro Compass? Select as many topics as you&apos;d like.</p>
      </header>
      <FieldValuesMultipleSelect
        values={onboardingTopics}
        selected={selectedIds}
        onSelect={onChange}
        baseClassName={'onboard-options-grid'}
        iconOn={<CheckmarkIcon />}
        iconOff={<CheckmarkIcon />} />
    </>
  )
}
