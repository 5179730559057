import type { AppProps } from 'next/app'
import { RecoilRoot } from 'recoil'
import { FirebaseAuthObserver, ReauthObserver, UserGuard, UserProfileObserver, UserTopicsDepreciator } from '../components/auth'
import { CompletedChallengeObserver } from '../components/content'
import { Layout } from '../components/layout'
import '../styles/global.scss'
import '../styles/tailwind.css'

if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'develop') {
  require('../styles/env.develop.scss')
}

const App = ({ Component, pageProps }: AppProps) => {

  return (
    <RecoilRoot>
      <FirebaseAuthObserver />
      <ReauthObserver />
      <Layout>
        <UserGuard>
          <Component {...pageProps} />
          <UserProfileObserver />
          <CompletedChallengeObserver />
          <UserTopicsDepreciator />
        </UserGuard>
      </Layout>
    </RecoilRoot>
  )
}

export default App
