import { Switch } from '@headlessui/react'
import { ToggleOff, ToggleOn } from '../icons'

interface Props {
  value: boolean
  onChange: (value: boolean) => void
}

export const NewFilter = ({ value, onChange }: Props) => {
  return (
    <Switch
      checked={value}
      onChange={onChange}
      className='new-filter'>
      <span className="new-filter__label">New items only</span>
      <span className="new-filter__toggle">
        {value ? <ToggleOn /> : <ToggleOff />}
      </span>
    </Switch>
  )
}
