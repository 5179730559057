interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const SlidersIcon = ({
  width = 88,
  height = 78,
  classNames = 'icon',
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox='0 0 88 78'
      className={classNames}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="currentColor" transform="translate(-359 -2231)">
          <g transform="translate(359 2231)">
            <path d="M66.95 3.821c3.61 0 6.546 2.996 6.546 6.679 0 3.683-2.936 6.679-6.545 6.679-3.61 0-6.545-2.996-6.545-6.679 0-3.683 2.935-6.679 6.545-6.679m-65.079 8.59h54.962C57.718 17.29 61.916 21 66.951 21c5.034 0 9.233-3.71 10.116-8.59h9.06c1.035 0 1.873-.855 1.873-1.91s-.838-1.91-1.872-1.91h-9.06C76.183 3.71 71.984 0 66.95 0c-5.035 0-9.233 3.71-10.117 8.59H1.872C.838 8.59 0 9.445 0 10.5s.838 1.91 1.872 1.91"></path>
            <path d="M23.151 46.179c-3.61 0-6.546-2.996-6.546-6.679 0-3.683 2.937-6.679 6.546-6.679 3.61 0 6.545 2.996 6.545 6.679 0 3.683-2.936 6.679-6.545 6.679m62.977-8.765H33.236C32.286 32.62 28.126 29 23.151 29c-4.974 0-9.135 3.62-10.086 8.414H1.872c-1.034 0-1.872.855-1.872 1.91s.838 1.91 1.872 1.91h11.131C13.816 46.202 18.056 50 23.151 50s9.334-3.799 10.147-8.765h52.83c1.034 0 1.872-.856 1.872-1.91 0-1.056-.838-1.911-1.872-1.911"></path>
            <path d="M48.68 74.18c-3.609 0-6.545-2.997-6.545-6.68s2.936-6.68 6.545-6.68c3.61 0 6.546 2.997 6.546 6.68s-2.936 6.68-6.545 6.68m37.447-8.59h-27.33C57.913 60.71 53.714 57 48.68 57c-5.034 0-9.233 3.71-10.116 8.59H1.872C.838 65.59 0 66.445 0 67.5s.838 1.91 1.872 1.91h36.692c.883 4.88 5.082 8.59 10.117 8.59 5.035 0 9.233-3.71 10.116-8.59h27.33c1.035 0 1.873-.855 1.873-1.91s-.838-1.91-1.872-1.91"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
