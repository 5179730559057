import { useUserTopics } from '../lib/state'
import { Popover } from '@headlessui/react'
import { topics } from '../lib/fields'

export const DebugUserTopics = () => {
  const [userTopics] = useUserTopics()
  if (!userTopics) return <span className='absolute left-1/4 top-0'>Debug</span>
  const flatTopics = topics.groups
    .flatMap(group => group.values)
  const myTopics = Object.entries(userTopics)
    .sort((a, b) => {
      return b[1].score - a[1].score
    })
    .map(([id, userTopic]) => ({
      id,
      ...userTopic,
      ...flatTopics.find(topic => topic.id === id)
    }))

  return (
    <Popover className='absolute left-1/4 top-0'>
      <Popover.Button>Debug</Popover.Button>

      <Popover.Panel className='absolute z-10 bg-white border-solid border-2 border-black max-h-96 overflow-auto'>
        <pre>{JSON.stringify(myTopics, null, 1)}</pre>
      </Popover.Panel>
    </Popover>
  )
}
