import { Switch } from '@headlessui/react'
import { CheckmarkIcon } from '../icons'

interface Props {
  selected: boolean
  onChange: (selected: boolean) => void
}

export const CardSelect = ({ selected, onChange }: Props) => {
  return (
    <Switch
      checked={selected}
      onChange={onChange}
      className={'card-select'}>
      <span className={'card-select__text'}>
        {selected ? 'Selected' : 'Select'}
      </span>
      <span className={'card-select__icon'}>
        {selected ? <CheckmarkIcon /> : <CheckmarkIcon />}
      </span>
    </Switch>
  )
}
