interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const SignIcon = ({
  width = 88,
  height = 88,
  classNames = 'icon',
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox='0 0 88 88'
      className={classNames}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="currentColor" transform="translate(-722 -1700)">
          <g transform="translate(722 1700)">
            <path d="M68.304 47.227H3.744V16.62h64.56l15.19 15.304-15.19 15.303zm19.155-16.614l-17.042-17.17a1.88 1.88 0 00-1.331-.553H41.19V1.865C41.191.835 40.353 0 39.319 0a1.869 1.869 0 00-1.873 1.865V12.89H1.872A1.869 1.869 0 000 14.755v34.338c0 1.03.838 1.865 1.872 1.865h35.574V84.27H23.394a1.869 1.869 0 00-1.872 1.865c0 1.03.838 1.865 1.872 1.865h31.85a1.869 1.869 0 001.873-1.865c0-1.03-.839-1.865-1.873-1.865H41.191V50.958h27.895c.5 0 .98-.2 1.331-.554L87.46 33.235a1.86 1.86 0 000-2.622z"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
