// Prismic Configuration, based on https://prismic.io/docs/technologies/nextjs#2.-add-config-and-helper-files
import { getRoute } from '../lib/prismic'

// -- Prismic Repo Name
export const repoName = process.env.NEXT_PUBLIC_PRISMIC_API_ENDPOINT

// -- Prismic API endpoint
// Determines which repository to query and fetch data from
// Configure your site's access point here
export const apiEndpoint = `https://${repoName}.cdn.prismic.io/api/v2`

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
// export const accessToken = 'MC5ZZ1Z3a0JFQUFDRUFLRkl1.Z--_ve-_vQ8dJO-_vQ8XUBjvv71E77-977-977-9e--_vSDvv73vv73vv71P77-9ZHHvv70W77-977-9Hms'
export const accessToken = ''

export const documentTypes = {
  article_v4: {
    route: "/articles/:uid",
    humanized_name: "article",
    algolia_config: {
      title: 'title',
      body: [
        'body'
      ]
    }
  },

  audio_v1: {
    route: "/audio-content/:uid",
    humanized_name: "audio content",
    algolia_config: {
      title: 'title',
      body: [
        'body'
      ]
    }
  },

  challenge_v1: {
    route: "/challenges/:uid",
    humanized_name: "challenge",
    algolia_config: {
      title: 'title',
      body: [
        'body'
      ]
    }
  },

  checkbox_task_v1: {
    route: null,
    algolia_config: {
      title: 'title',
      body: []
    }
  },

  course_resource_v1: {
    route: "/course-resources/:uid",
    humanized_name: "course resource",
    algolia_config: {
      title: 'title',
      body: [
        'body'
      ]
    }
  },

  partner_resource_v1: {
    route: "/partner-resources/:uid",
    humanized_name: "partner resource",
    algolia_config: {
      title: 'title',
      body: [
        'body'
      ]
    }
  },

  resource_v1: {
    route: "/resources/:uid",
    humanized_name: "resource",
    algolia_config: {
      title: 'title',
      body: [
        'body'
      ]
    }
  },

  video_v1: {
    route: "/video-content/:uid",
    humanized_name: "video",
    algolia_config: {
      title: 'title',
      body: [
        'body'
      ]
    }
  },
}

// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolver = (doc) => {
  let route = getRoute(doc.type)
  if (route) {
    return route.replace(':uid', doc.uid)
  }

  return '/'
}

// -- Route Resolver rules
// Manages the url links to internal Prismic documents two levels deep (optionals)
const _routes = []
for (const [key, value] of Object.entries(documentTypes)) {
  let route = value.route
  if (route) {
    _routes.push({ type: key, path: route })
  }
}

export const routeResolver = {
  routes: _routes
};
