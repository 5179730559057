import { DocumentType } from '../../lib/prismic'
import { contentPredicate, prettyDuration } from '../../lib/util'
import { AceIcon, ArticleIcon, AudioContentIcon, MtnFlagIcon, ResourceIcon, VideoContentIcon } from '../icons'

interface Props {
  minutes: number
  type: DocumentType
}

export const ContentDuration = ({ minutes, type }: Props) => {
  const icon = () => {
    switch (type) {
      case DocumentType.Article: return <ArticleIcon />
      case DocumentType.Audio: return <AudioContentIcon />
      case DocumentType.Challenge: return <MtnFlagIcon width={20} height={20} />
      case DocumentType.CourseResource: return <AceIcon width={24} height={21} />
      case DocumentType.PartnerResource: return <ResourceIcon />
      case DocumentType.Resource: return <ResourceIcon />
      case DocumentType.Video: return <VideoContentIcon />
      default: return null
    }
  }

  return (
    <span className='article-preheader__label'>
      <span className='article-preheader__label-icon'>{icon()}</span>
      <span className='article-preheader__label-text'>
        {prettyDuration(minutes)} {contentPredicate(type)}
      </span>
    </span>
  )
}
