import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export const NavNeedle = () => {
  const router = useRouter()
  const [direction, setDirection] = useState('')

  useEffect(() => {
    if (router.pathname.startsWith('/my-compass')) setDirection('west')
    else if (router.pathname.startsWith('/explore')) setDirection('east')
    else setDirection('unknown')
  }, [router.pathname])

  return (
    <div className={'nav__items nav__items--needle ' + direction}>
      <Link href="/my-compass">
        <a data-tour='my-compass' className='nav__item nav__item--mycompass'>My Compass</a>
      </Link>
      <div className='nav__item-needle'>
        <Image src='/assets/img/nav-needle.svg' alt='' width={14} height={56} />
      </div>
      <Link href='/explore'>
        <a data-tour='explore' className='nav__item nav__item--explore'>Explore</a>
      </Link>
    </div>
  )
}
