import Vimeo from '@u-wave/react-vimeo';
import Image from 'next/image';
import React, { useState } from 'react';
import { useAnalytics } from '../../lib/state';
import { PlayOutlineIcon } from '../icons';

interface Props {
  imageUrl: string;
  imageAlt: string;
  embedCodeVideoId: string;
  embedCodeWidth: number;
  embedCodeHeight: number;
}

export const HeroVideo = ({ imageUrl, imageAlt, embedCodeWidth, embedCodeHeight, embedCodeVideoId }: Props) => {
  const [embedHidden, setEmbedHidden] = useState(true);
  const aspectRatioPadding = embedCodeHeight / embedCodeWidth * 100 + '%';
  const analytics = useAnalytics()
  const [events, setEvents] = useState(['start', '25%', '50%', '75%', '90%', 'finish'])
  const getEvent = progress => {
    if (progress < 0.04 && events.includes('start')) {
      setEvents(events.filter(event => event !== 'start'))
      return 'start'
    }
    else if (progress > 0.25 && events.includes('25%')) {
      setEvents(events.filter(event => event !== '25%'))
      return '25%'
    }
    else if (progress > 0.50 && events.includes('50%')) {
      setEvents(events.filter(event => event !== '50%'))
      return '50%'
    }
    else if (progress > 0.75 && events.includes('75%')) {
      setEvents(events.filter(event => event !== '75%'))
      return '75%'
    }
    else if (progress > 0.90 && events.includes('90%')) {
      setEvents(events.filter(event => event !== '90%'))
      return '90%'
    }
    else if (progress > 0.98 && events.includes('finish')) {
      setEvents(events.filter(event => event !== 'finish'))
      return 'finish'
    }
    return null
  }

  return (
    <div className='article-video'>
      <div className='article-video__poster' onClick={() => setEmbedHidden(false)} style={{ display: embedHidden ? 'block' : 'none' }}>
        <PlayOutlineIcon />
        <Image
          width={embedCodeWidth || 1440}
          height={embedCodeHeight || 680}
          layout={'responsive'}
          src={imageUrl || '/assets/img/hero-placeholder@2x.jpg'}
          alt={imageAlt} />
      </div>
      {embedCodeVideoId &&
        <div className='article-video__embed' style={{ paddingBottom: aspectRatioPadding, display: embedHidden ? 'none' : 'block' }}>
          <Vimeo
            video={embedCodeVideoId}
            paused={embedHidden}
            onProgress={e => {
              const event = getEvent(e.percent)
              if (!event) return
              analytics('view', {
                'event_type': 'video_tracking',
                'video_tracking': event,
              })
            }}
          />
        </div>
      }
    </div>
  )
}
