import { Tag } from '../common'
import firebase from '../../lib/firebase'
import { Timestamp } from 'firebase/firestore'
import { useUserContentState } from '../../lib/state'

interface Props {
  contentId: string
  published: number
}

export const NewTag = ({ contentId, published }: Props) => {
  const [userContent] = useUserContentState(contentId)
  const created = Date.parse(firebase.auth.currentUser?.metadata.creationTime)

  return (
    published > created && // was published after user created
    !userContent?.viewed && // was not viewed by user
    published > Timestamp.now().toMillis() - 3600000 * 24 * 30 // was published within last 30 days
  ) ? <Tag type='new' content='New' /> : null
}
