import Image from 'next/image'
import Link from 'next/link'
import { AceIcon, ExternalIcon } from '../icons'
import { prettyDuration } from '../../lib/util'
import { database } from 'firebase-admin'

interface Props {
  consumptionTime: number;
  aceCredit: string;
  title: string;
  externalUrl: string;
  imageUrl: string;
  imageAlt: string;
}

export const HeroCourse = ({ consumptionTime, aceCredit, title, externalUrl, imageUrl, imageAlt }: Props) => {
  const domain = (new URL(externalUrl))

  return (
    <header className='hero-course'>
      <div className='hero-course__container'>
        <header className='hero-course__details'>
          <div className='hero-course__details-header'>
            <span className='hero-course__details-header-duration'><AceIcon width={33} height={29} /><span>{prettyDuration(consumptionTime)} ACE Course Resource</span></span>
            <span className='hero-course__details-header-credits'>{aceCredit}</span>
          </div>
          <div className='hero-course__details-body'>
            <h1 className='hero-course__details-body-title'>{title}</h1>
          </div>
          <div className='hero-course__details-footer'>
            <Link href={externalUrl || 'https://www.acefitness.org'}>
              <a className='hero-course__details-footer-link' target={'_blank'} rel="noreferrer">
                see complete details at {domain.hostname} <ExternalIcon />
              </a>
            </Link>
          </div>
        </header>
        <section className='hero-course__img'>
          <Image
            width={720}
            height={420}
            layout={'fill'}
            objectFit={'cover'}
            objectPosition={'center'}
            src={imageUrl || '/assets/img/hero-placeholder@2x.jpg'}
            alt={imageAlt} />
        </section>
      </div>
    </header>
  )
}
