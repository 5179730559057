import { Popover } from '@headlessui/react'
import { topics } from '../../lib/fields'
import { FieldValuesMultipleSelect } from '../forms'
import { ChevronDownIcon, ToggleCheckboxOff, ToggleCheckboxOn } from '../icons'

interface Props {
  selected: string[]
  onSelect(selected: string[]): void
}

export const TopicsFilter = ({ selected, onSelect }: Props) => {
  return (
    <Popover className='topics-filter'>
      <Popover.Button className='topics-filter__button'>Topics <ChevronDownIcon /></Popover.Button>

      <Popover.Panel className='topics-filter__panel'>
        <div className='topics-filter__panel-grid'>
          {topics.groups.map(({ label, values }) => (
            <div className='topics-filter__panel-group' key={label}>
              <div className='topics-filter__panel-group-label'>{label}</div>
              <FieldValuesMultipleSelect
                values={values}
                selected={selected}
                onSelect={onSelect}
                baseClassName={'topics-filter-group-options'}
                iconOff={<ToggleCheckboxOff />}
                iconOn={<ToggleCheckboxOn />} />
            </div>
          ))}
        </div>

        <div className="topics-filter__panel-footer">
          {/* <button className='topics-filter__reset-button'>Reset All Filters</button> */}
          <Popover.Button className='topics-filter__apply-button'>Apply</Popover.Button>
        </div>
      </Popover.Panel>
    </Popover>
  )
}
