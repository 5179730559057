import Image from 'next/image'

interface Props {
  url: string;
  alt: string;
}

export const HeroImage = ({ url, alt }: Props) => {
  return (
    <div className='article-hero'>
      <Image
        width={1440}
        height={680}
        layout={'responsive'}
        src={url || '/assets/img/hero-placeholder@2x.jpg'}
        alt={alt}
      />
    </div>
  )
}
