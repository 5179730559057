import type { NextPage } from 'next'
import Link from 'next/link'
import { Takeover } from '../components/layout'
import { useAnalyticsPageView } from '../lib/state'

const Custom404: NextPage = () => {
  useAnalyticsPageView({ title: '404', type: 'Error' })

  return (
    <Takeover>
      <div className='login'>
        <h2 className='login__headline'>Oops!</h2>
        <p className='login__message'>That page could not be found.</p>
        <Link href='/'>
          <button className='login__button'>Go home</button>
        </Link>
      </div>
    </Takeover>
  )
}

export default Custom404
