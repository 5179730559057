interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const PlayOutlineIcon = ({ width = 120, height = 120, classNames = 'icon' }: Props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 120 120"
      className={classNames}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-660 -445)">
          <g transform="translate(660 445)">
            <circle
              cx="60"
              cy="60"
              r="59"
              stroke="currentColor"
              strokeWidth="2"
              className="circle"
            ></circle>
            <path
              fill="currentColor"
              d="M38.5 46.196l34.594-4.771a1 1 0 011.128 1.127L69.45 77.146a1 1 0 01-1.698.57L37.93 47.894a1 1 0 01.57-1.698z"
              transform="rotate(45 55.455 60.192)"
              className="triangle"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
