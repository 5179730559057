interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const ToggleRadioOn = ({ width = 19, height = 19 , classNames = 'icon' }: Props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19 19"
      className={classNames}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-130 -805)">
          <g transform="translate(130 805)">
            <rect
              width="17.75"
              height="17.75"
              x="0.625"
              y="0.625"
              fill="#FFF"
              stroke="#DADFE1"
              strokeWidth="1.25"
              rx="8.875"
            ></rect>
            <rect
              width="13"
              height="13"
              x="3"
              y="3"
              fill="#5282DE"
              rx="6.5"
            ></rect>
          </g>
        </g>
      </g>
    </svg>
  );
}
