interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const PinningCircleIcon = ({ width = 40, height = 40, classNames = 'icon' }: Props) => {

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 40 40'
      className={classNames}
      focusable='false'
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g transform='translate(-333 -290)'>
          <g transform='translate(333 290)'>
            <circle className='pinned-circle' cx='20' cy='20' r='20'></circle>
            <path
              className='pinned-tack'
              d='M18.708 35.8c.495 0 .9-.405.9-.9V25h7.2c.495 0 .9-.405.9-.9a4.508 4.508 0 00-3.6-4.41l-.9-10.89h.9c.495 0 .9-.405.9-.9s-.405-.9-.9-.9h-10.8c-.495 0-.9.405-.9.9s.405.9.9.9h.9l-.9 10.89a4.5 4.5 0 00-3.6 4.41c0 .495.405.9.9.9h7.2v9.9c0 .495.405.9.9.9'
              transform='rotate(45 18.708 21.4)'
            ></path>
            <path
              className='unpinned-tack'
              d='M11.66 23.2a2.703 2.703 0 012.548-1.8c.495 0 .9-.405.9-.9l.9-11.7h5.4l.9 11.7c0 .495.405.9.9.9 1.17 0 2.178.756 2.547 1.8H11.66zm7.048 12.6c.495 0 .9-.405.9-.9V25h7.2c.495 0 .9-.405.9-.9a4.508 4.508 0 00-3.6-4.41l-.9-10.89h.9c.495 0 .9-.405.9-.9s-.405-.9-.9-.9h-10.8c-.495 0-.9.405-.9.9s.405.9.9.9h.9l-.9 10.89a4.5 4.5 0 00-3.6 4.41c0 .495.405.9.9.9h7.2v9.9c0 .495.405.9.9.9z'
              transform='rotate(45 18.708 21.4)'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
