interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const PathIcon = ({
  width = 116,
  height = 66,
  classNames = 'icon',
}: Props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 116 66'
      className={classNames}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#5282DE" transform="translate(-1111 -4252)">
          <g transform="translate(1111 4252)">
            <path d="M8.5 53.236a4.272 4.272 0 00-4.269 4.268A4.271 4.271 0 008.5 61.767a4.271 4.271 0 004.269-4.263A4.272 4.272 0 008.5 53.236m0 12.767c-4.687 0-8.5-3.813-8.5-8.499 0-4.691 3.813-8.503 8.5-8.503 4.687 0 8.5 3.812 8.5 8.503 0 4.686-3.813 8.499-8.5 8.499"></path>
            <path d="M76.5 40.236a4.271 4.271 0 00-4.269 4.262 4.272 4.272 0 004.269 4.267 4.272 4.272 0 004.269-4.267 4.271 4.271 0 00-4.269-4.262m0 12.765c-4.687 0-8.5-3.818-8.5-8.503C68 39.812 71.813 36 76.5 36c4.687 0 8.5 3.811 8.5 8.497C85 49.183 81.187 53 76.5 53"></path>
            <path d="M34.5 4.236a4.272 4.272 0 00-4.269 4.267 4.271 4.271 0 004.269 4.262 4.267 4.267 0 004.263-4.262A4.268 4.268 0 0034.5 4.236M34.5 17c-4.687 0-8.5-3.812-8.5-8.498C26 3.812 29.813 0 34.5 0 39.187 0 43 3.812 43 8.503 43 13.189 39.187 17 34.5 17"></path>
            <path d="M18.139 39.408a3.496 3.496 0 011.452 4.731 3.496 3.496 0 01-4.73 1.453 3.5 3.5 0 01-1.453-4.731 3.5 3.5 0 014.73-1.453"></path>
            <path d="M23.139 30.408a3.496 3.496 0 011.452 4.731 3.496 3.496 0 01-4.73 1.453 3.5 3.5 0 01-1.453-4.731 3.5 3.5 0 014.73-1.453"></path>
            <path d="M28.139 20.408a3.496 3.496 0 011.452 4.731 3.496 3.496 0 01-4.73 1.453 3.5 3.5 0 01-1.453-4.731 3.5 3.5 0 014.73-1.453"></path>
            <path d="M50.1 21.845a3.497 3.497 0 01-4.942.253 3.498 3.498 0 114.689-5.195 3.502 3.502 0 01.252 4.942"></path>
            <path d="M58.1 28.845a3.497 3.497 0 01-4.942.253 3.498 3.498 0 114.689-5.195 3.502 3.502 0 01.252 4.942"></path>
            <path d="M66.1 35.845a3.497 3.497 0 01-4.942.253 3.498 3.498 0 114.689-5.195 3.502 3.502 0 01.252 4.942"></path>
            <path d="M93.329 32.438a3.501 3.501 0 01-5.658 4.125 3.498 3.498 0 01.768-4.888 3.496 3.496 0 014.89.763"></path>
            <path d="M102.329 26.438a3.501 3.501 0 01-5.658 4.125 3.498 3.498 0 01.768-4.888 3.496 3.496 0 014.89.763"></path>
            <path d="M107.923 34.743c-1.16-.562-1.773-1.934-1.386-3.241l3.268-10.962-11.095.427c-1.448.043-2.656-1.108-2.708-2.599-.053-1.49 1.072-2.744 2.515-2.798l14.77-.568a2.598 2.598 0 012.176 1.054 2.8 2.8 0 01.423 2.442l-4.35 14.597c-.424 1.427-1.888 2.226-3.268 1.783a1.917 1.917 0 01-.345-.135"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
