import { Switch } from '@headlessui/react'
import { Value } from '../../lib/fields'

interface Props {
  values: Value[]
  selected: string[]
  onSelect(selected: string[]): void
  disabledIds?: string[]
  baseClassName?: string
  iconOn?: any
  iconOff?: any
}

export const FieldValuesMultipleSelect = ({ values, selected, onSelect, disabledIds = [], baseClassName = 'field-values-multi-select', iconOff = '☐', iconOn = '✔️' }: Props) => {
  return (
    <div className={baseClassName} role='group'>
      {values.map(({ id, name, help }) => {
        return (
          <Switch
            key={id}
            checked={selected.includes(id)}
            onChange={checked => onSelect(
              checked ?
                [...selected, id] :
                selected.filter(val => val !== id)
            )}
            disabled={disabledIds.includes(id)}
            className={`${baseClassName}__button`}>
            <span className={`${baseClassName}__button-icon`}>
              {selected.includes(id) ? iconOn : iconOff}
            </span>
            <span className={`${baseClassName}__button-text`}>
              {name && (
                <span className={`${baseClassName}__button-text-label`}>{name}</span>
              )}
              {help && (
                <span className={`${baseClassName}__button-text-desc`}>{help}</span>
              )}
            </span>
          </Switch>
        )
      })}
    </div>
  )
}
