import { Dialog } from '@headlessui/react'
import { AnimatePresence, motion } from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import { useTourState, useUserProfile } from '../../lib/state'
import { CloseIcon } from '../icons'

interface Props {
  open: boolean
  onClose?: () => void
}

export const CompletedOnboardingModal = ({ open, onClose }: Props) => {
  const [userProfile] = useUserProfile()
  const [tourState, setTourState] = useTourState()

  const backdrop = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 }
  }

  const scaleInUpScaleOutDown = {
    hidden: { scale: 0 },
    visible: { scale: 1 },
    exit: {
      scale: 0
    }
  }

  const popUpInSlideDownOut = {
    hidden: {
      y: '100%',
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
    },
    exit: {
      y: '-100%',
      opacity: 0,
    },
  }


  return (
    <AnimatePresence exitBeforeEnter>
      {open && (
        <Dialog
          static
          className='modal'
          as={motion.div}
          variants={backdrop}
          open={open}
          onClose={onClose}
          initial='hidden'
          animate='visible'
          exit='hidden'
          transition={{ delay: 0, type: 'tween' }}
        >
          <motion.div className='modal__inner'>
            <Dialog.Overlay className='modal__overlay' />
            <motion.div
              className='modal__body'
              variants={popUpInSlideDownOut}
              initial='hidden'
              animate='visible'
              exit='hidden'
              transition={{ duration: 0.5 }}
            >
              <button className='modal__x-btn' type='button' onClick={onClose}><CloseIcon width={24} height={24} /></button>
              <div className="modal__pre-title modal__pre-title--emoji">
                <Image src='/assets/img/ace-circle-logo-icon.svg' alt='ACE PRO Compass' width={72} height={72} />
              </div>
              <Dialog.Title className={'modal__title'}>
                Welcome, {userProfile?.name?.first}. You are in!
              </Dialog.Title>
              <Dialog.Description as={`div`} className={'modal__desc'}>
                <p>Let&apos;s take a quick tour of ACE Pro Compass. 2 minutes max, we promise.</p>
              </Dialog.Description>
              <Link href='/my-compass'>
                <a
                  className='modal__confirm-btn'
                  onClick={() => {
                    setTourState({ run: true, stepIndex: 0 })
                    onClose()
                  }}>
                  Go!
                </a>
              </Link>
            </motion.div>
          </motion.div>
        </Dialog>
      )}
    </AnimatePresence>
  )
}
