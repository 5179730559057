interface TokenData {
  token: string
  message?: string
}

export const getToken = async (code: string): Promise<TokenData> => {
  try {
    const response = await fetch(`/api/auth?code=${code}`)
    return await response.json()
  }
  catch (error) {
    return null
  }
}

interface RefreshTokenData {
  refresh: string
}

export const getTokenFromRefresh = async (refresh: string): Promise<RefreshTokenData> => {
  try {
    const response = await fetch(`/api/reauth?refresh=${refresh}`)
    return await response.json()
  }
  catch (error) {
    return null
  }
}
