interface Props {
  children: any
  classNames?: string
}

export const LayoutRow = ({ children, classNames }: Props) => {
  return (
    <section className={`layout-row${(classNames ? ' ' + classNames : '')}`}>
      <div className='layout-row__inner'>
        {children}
      </div>
    </section>
  )
}
