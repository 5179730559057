interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const ToggleRadioOff = ({ width = 19, height = 19 , classNames = 'icon' }: Props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19 19"
      className={classNames}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g
          fill="#FFF"
          stroke="#DADFE1"
          strokeWidth="1.25"
          transform="translate(-130 -769)"
        >
          <rect
            width="17.75"
            height="17.75"
            x="0.625"
            y="0.625"
            rx="8.875"
            transform="translate(130 769)"
          ></rect>
        </g>
      </g>
    </svg>
  );
}
