import { atom, useRecoilValue, useSetRecoilState } from 'recoil'

const onboardingState = atom<boolean>({
  key: 'onboarding',
  default: false,
})

export const useOnboarding = () => useRecoilValue(onboardingState)

export const useSetOnboarding = () => useSetRecoilState(onboardingState)
