export interface Value {
  id: string
  name: string
  help?: string
}

export interface Group {
  label: string
  values: Value[]
}

export interface Field {
  label: string
  type: 'single' | 'multiple'
  values?: Value[]
  groups?: Group[]
}

export interface IntegrationValue {
  id: string
  title: string
  description: string
  image_url?: string
  last_update: number
  blob: any
}

export const certifications: Field = {
  label: 'Certification',
  type: 'multiple',
  values: [
    { id: 'abgo2b', name: 'Personal Trainer' },
    { id: '4n0p1x', name: 'Group Fitness Instructor' },
    { id: 'rhzo56', name: 'Health Coach' },
    { id: '06hab2', name: 'Medical Exercise Specialist' },
  ]
}

export const experiences: Field = {
  label: 'Experience',
  type: 'single',
  values: [
    { id: 'yzqkbf', name: 'Less than 2 years' },
    { id: 'saal1q', name: '2 to 6 years' },
    { id: 'szfalj', name: 'More than 6 years' },
  ]
}

export const professionalInterests: Field = {
  label: 'Professional Interest',
  type: 'single',
  values: [
    { id: '5cha28', name: 'I want full time work' },
    { id: '5tpoxj', name: 'I want part time work' },
    { id: '93gp2h', name: 'Fitness is a passion project for me' },
    { id: 'bmwe2v', name: 'I\'m not sure' },
  ]
}

export const employmentTypes: Field = {
  label: 'Employment Type',
  type: 'single',
  values: [
    {
      id: '2r55k7',
      name: 'Self-Employment',
      help: 'I want to run my own fitness business'
    },
    {
      id: 'k577xf',
      name: 'Employment',
      help: 'I want to work for an established fitness company or gym'
    },
    {
      id: '4yr15y',
      name: 'Hybrid',
      help: 'I want to consult with or teach classes in studios and fitness companies, while maintaining some independence'
    },
    // {
    //   id: 'f1p2l1',
    //   name: 'Virtual'
    // },
    {
      id: '2vnejf',
      name: 'I\'m not sure'
    },
  ]
}

export const topics: Field = {
  label: 'Topics of Interest',
  type: 'multiple',
  groups: [
    {
      label: 'Professional Opportunities',
      values: [
        { id: 'siargd', name: 'Professional Opportunities' },
        { id: 'umysi6', name: 'Starting Your Career' },
        { id: 'vu892d', name: 'Landing a Job' },
        { id: '9fau0x', name: 'Career Development' },
      ],
    },
    {
      label: 'Sales',
      values: [
        { id: '7f0517', name: 'Sales' },
        { id: 'w73wgv', name: 'Client Retention' },
        { id: 'hcnqp2', name: 'Referral Strategy' },
        { id: 'zuec40', name: 'Pricing Strategies' },
      ],
    },
    {
      label: 'Marketing',
      values: [
        { id: '32zkvu', name: 'Marketing' },
        { id: 'odz5ns', name: 'Branding' },
        { id: 'jwo73c', name: 'Market Research' },
        { id: 'ea2lzd', name: 'Social Media' },
        { id: 'gstmr4', name: 'Content Strategy' },
        { id: 'p9ic03', name: 'Website Guide' },
      ],
    },
    {
      label: 'Community',
      values: [
        { id: 'jfx53l', name: 'Community' },
        { id: '6f1mxl', name: 'Mentorship' },
        { id: 'bslgyq', name: 'Networking' },
        { id: '611sja', name: 'Equity, Diversity & Inclusion' },
      ],
    },
    {
      label: 'Virtual Environment',
      values: [
        { id: '8flqk0', name: 'Virtual Environment' },
        { id: 're8pfw', name: 'Technology & Set-up' },
        { id: 'ksvaor', name: 'Online Business' },
      ],
    },
    {
      label: 'Running a Business',
      values: [
        { id: 'wwirmn', name: 'Running a Business' },
        { id: 'qamyrc', name: 'Starting a Business' },
        { id: 'nryzsd', name: 'Business Tools & Operations' },
        { id: 'cbgm8z', name: 'Legal Considerations' },
        { id: 'pc4l9y', name: 'Budgeting & Financial Planning' },
        { id: '93zp2i', name: 'Client Management' },
      ],
    },
    {
      label: 'Personal Development',
      values: [
        { id: 'cus0xy', name: 'Personal Development' },
        { id: 'g2qs9o', name: 'Work-life Balance' },
        { id: '3ibkw6', name: 'Wellness & Self-care' },
      ],
    },
  ]
}

export const toIntegrationValues = (field: Field): IntegrationValue[] => {
  if (field.groups) {
    return field.groups.flatMap(group => group.values.map(({ id, name }) => ({
      id,
      title: name,
      description: `${field.label} - ${group.label}`,
      image_url: 'https://images.unsplash.com/photo-1505778276668-26b3ff7af103?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=160&h=100&q=80',
      last_update: 1639468800000,
      blob: {
        id,
        group: group.label,
        name
      }
    })))
  }
  return field.values.map(({ id, name }) => ({
    id,
    title: name,
    description: field.label,
    image_url: 'https://images.unsplash.com/photo-1505778276668-26b3ff7af103?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=160&h=100&q=80',
    last_update: 1639468800000,
    blob: {
      id,
      name
    }
  }))
}

// reduces the topics list to only include values that match a list of topic IDs, and removes their parent category if no values remain
export const filteredTopics = (topicIds?: string[]) => {
  if (!topicIds)
    return { ...topics }

  const topicsCopy = { ...topics }
  topicsCopy.groups = topicsCopy.groups.map(group => ({
    ...group,
    values: group.values.filter(({ id }) => topicIds.includes(id))
  }))
    .filter(group => group.values.length)

  return topicsCopy
}
