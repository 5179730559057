import { SearchResponse } from '@algolia/client-search'
import { Timestamp } from 'firebase/firestore'
import { selectorFamily, useRecoilValueLoadable } from 'recoil'
import algolia from '../algolia'
import { registerContent } from '../name'
import { expandLoadableValue } from '../recoil'

export type SearchQuery = {
  query?: string
  page?: number
  hitsPerPage?: number
  includeIds?: string[]
  excludeIds?: string[]
  topicIds?: string[]
  types?: string[]
  excludeTypes?: string[]
  recent?: boolean
  scoredTopicIds?: string[]
  profile?: {
    certifications?: string[]
    experience?: string
    professionalInterest?: string
    employmentType?: string
  }
}

export const loadSearchResponse = async (searchQuery: SearchQuery) => {
  let filters = ''
  if (searchQuery.includeIds && searchQuery.includeIds.length) {
    filters += `(${searchQuery.includeIds
      .map(id => `objectID:${id}`)
      .join(' OR ')})`
  }
  if (searchQuery.excludeIds && searchQuery.excludeIds.length) {
    if (filters) filters += ' AND '
    filters += `(${searchQuery.excludeIds
      .map(id => `NOT objectID:${id}`)
      .join(' AND ')})`
  }
  if (searchQuery.topicIds && searchQuery.topicIds.length) {
    if (filters) filters += ' AND '
    filters += `(${searchQuery.topicIds
      .map(id => `topic_ids:${id}`)
      .join(' OR ')})`
  }
  if (searchQuery.types && searchQuery.types.length) {
    if (filters) filters += ' AND '
    filters += `(${searchQuery.types
      .map(type => `type:${type}`)
      .join(' OR ')})`
  }
  if (searchQuery.excludeTypes && searchQuery.excludeTypes.length) {
    if (filters) filters += ' AND '
    filters += `(${searchQuery.excludeTypes
      .map(type => `NOT type:${type}`)
      .join(' AND ')})`
  }
  if (searchQuery.recent) {
    if (filters) filters += ' AND '
    filters += `(first_publication_timestamp > ${Timestamp.now().toMillis() - 3600000 * 24 * 30})`
  }
  if (searchQuery.scoredTopicIds && searchQuery.scoredTopicIds.length) {
    if (filters) filters += ' AND '
    filters += `(${searchQuery.scoredTopicIds
      .map((id, index) => `topic_ids:${id}<score=${3 - Math.floor(index / 3)}>`)
      .join(' OR ')})`
  }
  if (searchQuery.profile) {
    let profileFilters = ''
    if (searchQuery.profile.certifications && searchQuery.profile.certifications.length)
      profileFilters += `(${searchQuery.profile.certifications
        .map(id => `certification_ids:${id}`)
        .join(' OR ')})`
    if (searchQuery.profile.experience) {
      if (profileFilters) profileFilters += ' AND '
      profileFilters += `experience_ids:${searchQuery.profile.experience}`
    }
    if (searchQuery.profile.professionalInterest) {
      if (profileFilters) profileFilters += ' AND '
      profileFilters += `professional_interest_ids:${searchQuery.profile.professionalInterest}`
    }
    if (searchQuery.profile.employmentType) {
      if (profileFilters) profileFilters += ' AND '
      profileFilters += `employment_type_ids:${searchQuery.profile.employmentType}`
    }
    if (filters && profileFilters) filters += ' AND '
    filters += profileFilters
  }
  const page = searchQuery.page ?? 0
  const hitsPerPage = searchQuery.hitsPerPage ?? 20
  const response = await algolia.index.search(searchQuery.query ?? '', { filters, page, hitsPerPage })
  response?.hits.forEach((hit: any) => {
    registerContent(hit.objectID, { title: hit.title, contributor: hit.contributor_name, duration: hit.consumption_time, type: hit.type })
  })
  return response
}

const searchResponseQuery = selectorFamily<SearchResponse<any>, SearchQuery>({
  key: 'searchResponse',
  get: searchQuery => () => loadSearchResponse(searchQuery),
})

export const useSearchResponse = (searchQuery: SearchQuery) => expandLoadableValue(useRecoilValueLoadable(searchResponseQuery(searchQuery)))
