import Link from 'next/link'
import { filteredTopics } from '../../lib/fields'
import { useAnalytics } from '../../lib/state'

interface Props {
  topicIds?: string[]
}

export const TopicsList = ({ topicIds }: Props) => {
  const topics = filteredTopics(topicIds)
  const analytics = useAnalytics()

  return (
    <div className='topics-list'>
      {topics.groups.map(({ label, values }) => (
        <ul className='topics-list__list' key={label}>
          <li className='topics-list__topic-item'>
            <span className="topics-list__topic-item-label">{label}</span>
            <ul className='topics-list__topic-list'>
              {values.map(({ id, name }) => (
                <li className='topics-list__topic-list-item' key={id}>
                  <Link href={{
                    pathname: '/explore',
                    query: { t: id },
                  }}>
                    <a onClick={() => {
                      analytics('link', {
                        'event_type': 'search_topic',
                        'page_type': 'Explore',
                        'topic_id': id,
                      })
                    }}>
                      {name}
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        </ul>
      ))}
    </div>
  )
}
