import { pluralMaybe, prettyDuration } from '../../lib/util'

interface Props {
  tasks: string[]
  minutes: number
}

export const ChallengeLength = ({ tasks, minutes }: Props) => {
  return (
    <span className='article-preheader__details'>
      {pluralMaybe(tasks.length, 'task')}
      <span className='divider'>|</span>
      {prettyDuration(minutes)}
    </span>
  )
}
