import { atom, useRecoilValue, useSetRecoilState } from 'recoil'
import { User } from '../models'

export const userState = atom<User>({
  key: 'user',
  default: null
})

export const useUser = () => useRecoilValue(userState)

export const useSetUser = () => useSetRecoilState(userState)
