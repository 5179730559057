import { useSearchResponse } from '../../lib/state'
import { Card } from '../cards'
import { Sentiment } from '../content'

interface Props {
  contentId: string
}

export const ContentPanel = ({ contentId }: Props) => {
  const [response, loading, error] = useSearchResponse({ includeIds: [contentId] })
  const content = response?.hits?.at(0)

  return !content ? null : (
    <div className='onboard__panel-inner'>
      <div className='onboard__panel-inner-content'>
        <figure className='grid grid--sentiment'>
          <Card data={content} />
          <figcaption className='sentiment-card-panel'>
            <div className='sentiment-card-panel__content'>
              <h2>Do you want to see more like this?</h2>
              <p>Compass gets smarter when you react.</p>
              <Sentiment contentId={contentId} topicIds={content.topic_ids} />
            </div>
          </figcaption>
        </figure>
      </div>
    </div>
  )
}
