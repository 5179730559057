import Image from 'next/image'

export const Takeover = ({ children }) => {
  return (
    <div className='takeover'>
      <div className='takeover__shell-inner'>
        <article className='takeover__shell-container'>
          <header className='takeover__shell-header'>
            <span className='takeover__header-section takeover__header-section--branding'>
              <span className='takeover__logo-desktop'>
                <Image src="/assets/img/logo-primary.svg" alt="ACE PRO Compass" width={160} height={43} />
              </span>
              <span className='takeover__logo-mobile'>
                <Image src="/assets/img/ace-circle-logo-icon.svg" alt="ACE PRO Compass" width={26} height={26} />
              </span>
            </span>
          </header>
          <section className='takeover__shell-body'>
            {children}
          </section>
        </article>
      </div>
    </div>
  )
}
