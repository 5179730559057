import { CloseIcon } from '../icons'

interface Props {
  open: boolean
  onClose: () => void
  children: any
}

export const Notify = ({ open, onClose, children }: Props) => {

  // Examples
  // <Notify>
  // <p>Hey Molly, ACE® Pro Compass has new suggestions for you!</p>
  // <Link href={'#'}>
  //   <a className='notify-button'>Review Suggestions</a>
  // </Link>
  // </Notify>

  // <Notify>
  // <div className='notify-split'>
  //   <div><p>Help Compass get to know you better.</p></div>
  //   <div>
  //     <Link href={'#'}>
  //       <a className='arrow-link'>Tune your compass <ArrowLeftIcon/></a>
  //     </Link>
  //   </div>
  // </div>
  // </Notify>

  return !open ? null : (
    <section className='notify notify--basic'>
      <span className='notify__left'></span>
      <span className='notify__middle'>
        {children}
      </span>
      <span className='notify__right'>
        <button className='notify-dismiss-btn' onClick={onClose} type='button'><CloseIcon /></button>
      </span>
    </section>
  )
}
