import Image from 'next/image'
import Link from 'next/link'
import { useUser, useUserProfile } from '../../lib/state'
import { NavNeedle } from './nav-needle'
import { UserMenu } from './user-menu'

export const Nav = () => {
  const user = useUser()
  const [userProfile] = useUserProfile()

  return (
    <nav className='layout__page-nav'>
      <div className='nav'>
        <div className='nav__inner'>
          <div className='nav__branding'>
            <Link href='/'>
              <a>
                <span className='nav__branding-logo-desktop'>
                  <Image src='/assets/img/logo-primary.svg' alt='ACE PRO Compass' width={160} height={43} />
                </span>
                <span className='nav__branding-logo-mobile'>
                  <Image src='/assets/img/ace-circle-logo-icon.svg' alt='ACE PRO Compass' width={26} height={26} />
                </span>
              </a>
            </Link>
          </div>
          {user ? <NavNeedle /> : null}
          <div className={`nav__user${user ? ' nav__user--logged-in' : ''}`}>
            {user ? <UserMenu user={user} userProfile={userProfile} /> : (
              <a className='nav__user-login' href={process.env.NEXT_PUBLIC_AUTH_URL}>Sign in</a>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
