import { atom, useRecoilState } from 'recoil'

interface TourState {
  run: boolean
  stepIndex: number
}


const initialTourState: TourState = {
  run: false,
  stepIndex: 0,
}

const tourState = atom<TourState>({
  key: 'tour',
  default: initialTourState,
})

export const useTourState = () => useRecoilState(tourState)
