interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const ArticleIcon = ({ width = 20, height = 20 , classNames = 'icon' }: Props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      className={classNames}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="currentColor" fillRule="nonzero">
          <path d="M3.773 13.852s-.003 0-.003.003a.767.767 0 00-.712.822c.032.413.38.727.79.715h7.688a.77.77 0 100-1.537H3.848c-.023-.003-.05-.003-.075-.003zm11.612-2.305h3.078v5.383c0 .863-.672 1.534-1.532 1.537h-.009a1.518 1.518 0 01-1.537-1.537v-5.383zm-11.537-.77a.765.765 0 00-.784.75.768.768 0 00.752.787h7.72a.77.77 0 00.032-1.538h-7.72zM3.77 7.703a.77.77 0 00.078 1.54h7.688c.425 0 .77-.345.77-.77a.772.772 0 00-.77-.77H3.848a.354.354 0 00-.078 0zm0-3.075a.767.767 0 00-.712.823c.032.412.38.726.79.715h7.688a.77.77 0 100-1.538H3.848a.354.354 0 00-.078 0zM1.54 1.551h12.307v9.083a.825.825 0 000 .267v6.029c0 .56.163 1.084.43 1.537H3.078A1.518 1.518 0 011.54 16.93V1.55zM14.6-.001c-.035.003-.073.006-.108.011H.773C.35.01.003.35 0 .778V16.93a3.086 3.086 0 003.078 3.072h13.844a.799.799 0 00.276-.029C18.76 19.831 20 18.525 20 16.93v-6.154a.77.77 0 00-.77-.767h-3.845V.784a.77.77 0 00-.753-.785H14.6z"></path>
        </g>
      </g>
    </svg>
  );
}
