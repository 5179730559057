interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const CheckmarkIcon = ({ width = 30, height = 24 , classNames = 'icon' }: Props) => {

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 25 23'
      className={classNames}
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g fill='currentColor' transform='translate(-132 -623)'>
          <path d='M158.923412 623L141.997824 640.111698 135.076588 633.109058 132 636.219876 141.997824 646.333333 162 626.110818z'></path>
        </g>
      </g>
    </svg>
  );
}
