
import { useEffect } from 'react'
import { DocumentType, getHumanizedName } from '../../lib/prismic'
import { useBestRelatedChallengeInfo, useSetLastViewedChallenge } from '../../lib/state'
import { ContentLink } from '../content'
import { ArrowLeftIcon, MtnFlagIcon } from '../icons'

interface Props {
  contentId: string
  type: DocumentType
}

export const BackToChallenge = ({ contentId, type }: Props) => {
  const [challengeInfo] = useBestRelatedChallengeInfo(contentId)
  const setLastViewedChallenge = useSetLastViewedChallenge()

  useEffect(() => {
    if (challengeInfo) setLastViewedChallenge(challengeInfo.id)
  }, [challengeInfo])

  return !challengeInfo ? null : (
    <section className='notify notify--back'>
      <ContentLink type={DocumentType.Challenge} uid={challengeInfo.uid}>
        <a className='notify__back-link'>
          <span className="notify__back-link-icons">
            <ArrowLeftIcon />
            <MtnFlagIcon />
          </span>
          <span className='notify__back-link-content'>
            <span className='label'>This {getHumanizedName(type) ?? 'article'} is a part of</span>
            <span className='title'>{challengeInfo.title}</span>
          </span>
        </a>
      </ContentLink>
    </section>
  )
}
