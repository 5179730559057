import { employmentTypes } from '../../lib/fields'
import { useAnalytics, useAnalyticsPageView, useUserProfileState } from '../../lib/state'
import { snakeCase } from '../../lib/util'
import { FieldValuesSelect } from '../forms'
import { CheckmarkIcon } from '../icons'

export const EmploymentTypePanel = () => {
  const [userProfile, setUserProfile] = useUserProfileState()

  const analytics = useAnalytics()
  useAnalyticsPageView({ title: 'Employment Type', type: 'Onboarding' })

  return (
    <>
      <header className='onboard__panel-header'>
        <h2>Employment Type</h2>
        <p>Where do you want to take your career as a certified health &amp; fitness professional?</p>
      </header>
      <section className='onboard__panel-content'>
        <FieldValuesSelect
          values={employmentTypes.values}
          selected={userProfile?.employmentType}
          onSelect={employmentType => {
            setUserProfile({ ...userProfile, employmentType })
            analytics('link', {
              'event_type': 'onboarding',
              [snakeCase('employmentType')]: employmentType,
            })
          }}
          baseClassName={'onboard-options-stack'}
          iconOn={<CheckmarkIcon />}
          iconOff={<CheckmarkIcon />} />
      </section>
    </>
  )
}
