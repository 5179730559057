import { useEffect } from 'react'
import { atom, selector, selectorFamily, useRecoilValueLoadable, useSetRecoilState } from 'recoil'
import algolia from '../algolia'
import { registerContent } from '../name'
import { DocumentType } from '../prismic'
import { expandLoadableValue } from '../recoil'
import { userContentsEnrolled, userContentsState } from './user-contents-state'

const loadChallenges = async () => {
  const filters = `type:${DocumentType.Challenge}`
  const hitsPerPage = 100
  const response = await algolia.index.search<any>('', { filters, hitsPerPage })
  response?.hits.forEach((hit: any) => {
    registerContent(hit.objectID, { title: hit.title, contributor: hit.contributor_name, duration: hit.consumption_time, type: hit.type })
  })
  return response.hits
}

const challengesState = atom<any[]>({
  key: 'challenges',
  default: loadChallenges(),
})

export const useChallenges = () => expandLoadableValue(useRecoilValueLoadable(challengesState))

interface ChallengeInfo {
  id: string
  uid: string
  title: string
  taskIds: string[]
}

const challengeContentMap = selector<{ [contentId: string]: ChallengeInfo[] }>({
  key: 'challengeContentMap',
  get: ({ get }) => get(challengesState).reduce((map, hit) => {
    const { objectID, uid, title, task_ids } = hit
    const info = {
      id: objectID,
      uid,
      title,
      taskIds: task_ids,
    }
    map[objectID] = [info]
    hit.task_ids.forEach(contentId => {
      map[contentId] = [...(map[contentId] || []), info]
    })
    return map
  }, {})
})

export const useChallengeContentMap = () => expandLoadableValue(useRecoilValueLoadable(challengeContentMap))

const enrolledChallenges = selector<any[]>({
  key: 'enrolledChallenges',
  get: ({ get }) => {
    const enrolledIds = get(userContentsEnrolled)
    const challenges = get(challengesState)
    return challenges.filter(({ objectID }) => enrolledIds.includes(objectID))
  }
})

export const useEnrolledChallenges = () => expandLoadableValue(useRecoilValueLoadable(enrolledChallenges))

const completedChallenges = selector<any[]>({
  key: 'completedChallenges',
  get: ({ get }) => {
    const userContents = get(userContentsState)
    const challenges = get(enrolledChallenges)
    return challenges
      .filter(({ objectID, task_ids }) => task_ids.length <= userContents[objectID].completions.length)
      .sort((a, b) => {
        const aCompleted = userContents[a.objectID].completed
        const bCompleted = userContents[b.objectID].completed
        const aMillis = (aCompleted?.toMillis && aCompleted?.toMillis()) ?? 0
        const bMillis = (bCompleted?.toMillis && bCompleted?.toMillis()) ?? 0
        return bMillis - aMillis
      })
  }
})

export const useCompletedChallenges = () => expandLoadableValue(useRecoilValueLoadable(completedChallenges))

const incompletedChallenges = selector<any[]>({
  key: 'incompletedChallenges',
  get: ({ get }) => {
    const userContents = get(userContentsState)
    const challenges = get(enrolledChallenges)
    return challenges
      .filter(({ objectID, task_ids }) => task_ids.length > userContents[objectID].completions.length)
      .sort((a, b) => {
        const aEnrolled = userContents[a.objectID].enrolled
        const bEnrolled = userContents[b.objectID].enrolled
        const aMillis = (aEnrolled?.toMillis && aEnrolled?.toMillis()) ?? 0
        const bMillis = (bEnrolled?.toMillis && bEnrolled?.toMillis()) ?? 0
        return bMillis - aMillis
      })
  }
})

export const useIncompletedChallenges = () => expandLoadableValue(useRecoilValueLoadable(incompletedChallenges))

const incompleteTasks = selector<string[]>({
  key: 'incompleteTasks',
  get: ({ get }) => {
    const userContents = get(userContentsState)
    const challenges = get(incompletedChallenges)
    return challenges
      .flatMap(({ objectID, task_ids }) => (
        task_ids.filter(id => !userContents[objectID]?.completions.includes(id))
      ))
      .slice(0, 3)
  }
})

export const useIncompleteTasks = () => expandLoadableValue(useRecoilValueLoadable(incompleteTasks))

const lastViewedChallengeState = atom<string>({
  key: 'lastViewedChallenge',
  default: null,
})

export const useSetLastViewedChallenge = () => useSetRecoilState(lastViewedChallengeState)

export const useViewedChallenge = (id: string) => {
  const setLastViewedChallenge = useSetRecoilState(lastViewedChallengeState)

  useEffect(() => {
    setLastViewedChallenge(id)
  }, [])
}

export const relatedChallengePriority = selector<string[]>({
  key: 'relatedChallengePriority',
  get: ({ get }) => {
    return [
      get(lastViewedChallengeState),
      ...get(userContentsEnrolled)
    ]
      .filter(id => !!id)
  }
})

const bestRelatedChallengeInfo = selectorFamily<ChallengeInfo, string>({
  key: 'bestRelatedChallengeInfo',
  get: id => async ({ get }) => {
    const map = get(challengeContentMap)
    const priority = get(relatedChallengePriority)
    if (!map || !map[id]) return null
    let bestPriority = Infinity
    return map[id].reduce((best, info) => {
      const currentPriority = priority.indexOf(info.id)
      if (currentPriority > -1 && currentPriority < bestPriority) {
        bestPriority = currentPriority
        return info
      }
      return best
    })
  }
})

export const useBestRelatedChallengeInfo = (contentId: string) => expandLoadableValue(useRecoilValueLoadable(bestRelatedChallengeInfo(contentId)))
