interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const SummitIcon = ({
  width = 121,
  height = 75,
  classNames = 'icon',
}: Props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 121 75'
      className={classNames}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="currentColor" transform="translate(-781 -4143)">
          <g transform="translate(781 4143)">
            <path d="M65.1 0L39 9.961l21.163 7.46v4.362l-9.498 15.71-4.697-7.867-5.616 9.064 2.87 4.75 2.746-4.265L50.64 47l11.992-19.833 11.523 18.798L77 41.203l-11.9-19.42V0zM52.943 9.782l7.221-2.752v5.3l-7.22-2.548z"></path>
            <path d="M10.562 25.878l20.52 7.393v4.323l-9.21 15.563-4.547-7.79L0 75h58l-9.138-15.445-2.807-4.743-4.308-7.287-2.783-4.702-3.094-5.23V16l-25.308 9.878zm13.513-.184l7.008-2.727v5.253l-7.008-2.526zm9.401 17.236l2.73 4.613 2.782 4.707 1.514 2.562 2.807 4.743 6.33 10.696H8.307l9.019-15.422 4.524 7.75 11.627-19.65z"></path>
            <path d="M73.556 25.878l20.527 7.393v4.323l-9.21 15.563-4.554-7.79-.27.462-2.764 4.726-2.49 4.257-2.77 4.743L63 75h58L98.864 37.594V16l-25.308 9.878zm13.519-.184l7.008-2.727v5.253l-7.008-2.526zm-.437 33.86l.76-1.292L96.47 42.93l16.17 27.32H71.306l6.254-10.695 2.759-4.726 2.758 4.726 1.766 3.024 1.795-3.024z"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
