import { NotificationObserver } from '../auth'
import { DebugUserTopics } from '../debug-user-topics'
import { Nav } from '../nav'
import { SiteFooter } from './site-footer'

export const Layout = ({ children }) => {
  return (
    <>
      <div className='layout__page'>
        <header className='layout__page-header'>
          <Nav />
          {process.env.NEXT_PUBLIC_USER_TOPICS_DEBUGGER === '1' &&
            <DebugUserTopics />
          }
        </header>
        <main id="main-content" className='layout__page-main'>
          <NotificationObserver />
          {children}
        </main>
      </div>
      <footer className='layout__page-footer'>
        <SiteFooter />
      </footer>
    </>
  )
}
