interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const ToggleCheckboxOff = ({ width = 15, height = 15 , classNames = 'icon' }: Props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 15 15"
      className={classNames}
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g
          fill='#FFF'
          stroke='#DADFE1'
          strokeWidth='1.25'
          transform='translate(-130 -115)'
        >
          <rect
            width='13.75'
            height='13.75'
            x='0.625'
            y='0.625'
            rx='2'
            transform='translate(130 115)'
          ></rect>
        </g>
      </g>
    </svg>
  );
}
