import Link from 'next/link'
import { UrlObject } from 'url'

interface Props {
  type: 'sponsored' | 'new' | 'topic'
  content: string
  href?: string | UrlObject
  onClick?: () => void
}

export const Tag = ({ type, content, href, onClick }: Props) => {

  const classes = `tag tag--${type}`

  const LinkTag = () => {
    return <Link href={href}><a className={classes} onClick={onClick}>{content}</a></Link>
  }

  return (
    <>
      {
        href ? <LinkTag /> : <span className={classes}>{content}</span>
      }
    </>
  )
}
