import { doc, getDoc, setDoc, Timestamp } from 'firebase/firestore'
import { atom, AtomEffect, selector, useRecoilStateLoadable, useRecoilValueLoadable } from 'recoil'
import firebase from '../firebase'
import { expandLoadableState, expandLoadableValue } from '../recoil'
import { authState } from './auth-state'

export interface UserProfile {
  certifications: string[]
  experience: string
  professionalInterest: string
  employmentType: string
  allowEmail: boolean
  groups?: string[]
  name?: { first: string, last: string }
  pro?: boolean
  refresh?: string
  depreciated?: Timestamp
  sentimentalized?: Timestamp
}

const initialUserProfile: UserProfile = {
  certifications: [],
  experience: null,
  professionalInterest: null,
  employmentType: null,
  allowEmail: true,
}

const loadUserProfile = async () => {
  const uid = await firebase.userUID
  const snapshot = await getDoc(doc(firebase.db, 'users', uid))
  return (snapshot.data() as UserProfile) ?? initialUserProfile
}

const saveUserProfile = async (userProfile: UserProfile) => {
  const uid = await firebase.userUID
  await setDoc(doc(firebase.db, 'users', uid), userProfile, { merge: true })
}

const firebaseSyncEffect: AtomEffect<UserProfile> = ({ onSet }) => {
  onSet((newValue, oldValue, isReset) => {
    saveUserProfile(newValue)
  })
}

export const userProfileState = atom<UserProfile>({
  key: 'userProfile',
  default: loadUserProfile(),
  effects_UNSTABLE: [firebaseSyncEffect],
})

export const useUserProfile = () => expandLoadableValue(useRecoilValueLoadable(userProfileState))

export const useUserProfileState = () => expandLoadableState(useRecoilStateLoadable(userProfileState))

const shouldOnboard = selector<boolean>({
  key: 'shouldOnboard',
  get: ({ get }) => {
    const auth = get(authState)
    const userProfile = get(userProfileState)
    return auth && userProfile && (
      !userProfile.certifications ||
      !userProfile.employmentType ||
      !userProfile.experience ||
      !userProfile.professionalInterest
    )
  }
})

export const useShouldOnboard = () => expandLoadableValue(useRecoilValueLoadable(shouldOnboard))
