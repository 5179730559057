interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const MobileMenuCloseIcon = ({ width = 17, height = 17, classNames = 'icon' }: Props) => {

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={width}
      height={height}
      viewBox='0 0 17 17'
      className={classNames}
    >
      <g
        fill='none'
        fillRule='evenodd'
        stroke='none'
        strokeLinecap='square'
        strokeWidth='1'
      >
        <g stroke='currentColor' strokeWidth='2' transform='translate(-292 -16)'>
          <g transform='translate(293.782 17.782)'>
            <path
              d='M-2.282 6.718L15.718 6.718'
              transform='rotate(45 6.718 6.718)'
            ></path>
            <path
              d='M-2.282 6.718L15.718 6.718'
              transform='rotate(-45 6.718 6.718)'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
