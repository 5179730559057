import { PrismicTopic } from '../../lib/models'
import { ScoreAction, useAnalytics, useUserTopicCallback } from '../../lib/state'
import { Tag } from '../common'

interface Props {
  prismicTopics: PrismicTopic[]
  classNames?: string
}

export const TopicLinks = ({ prismicTopics, classNames }: Props) => {
  const userTopicCallback = useUserTopicCallback()
  const analytics = useAnalytics()

  return (
    <div className={`tag-group${(classNames ? ' ' + classNames : '')}`}>
      {prismicTopics.filter(topic => topic.topic != null).map(({ topic }) =>
        <Tag
          type='topic'
          href={{
            pathname: '/explore',
            query: { t: topic.id },
          }}
          onClick={() => {
            userTopicCallback({ action: ScoreAction.SearchTopic }, [topic.id])
            analytics('link', {
              'event_type': 'search_topic',
              'page_type': 'Content',
              'topic_id': topic.id,
            })
          }}
          content={topic.name}
          key={topic.id}
        />
      )}
    </div>
  )
}
