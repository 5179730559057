import { DocumentType } from '../../lib/prismic'

interface Props {
  type?: DocumentType
  children?: any
}

export const ArticlePreheader = ({ type, children }: Props) => {
  const theme = () => {
    switch (type) {
      case DocumentType.Challenge: return 'purple'
      case DocumentType.CourseResource: return 'red'
      default: return 'blue'
    }
  }

  return (
    <div className={`article-preheader${theme() ? ' article-preheader--' + theme() : ''}`}>
      {children}
    </div>
  )
}
