import { useEffect } from 'react'
import { certifications } from '../../lib/fields'
import { useAnalytics, useAnalyticsPageView, useUserProfileState } from '../../lib/state'
import { aceGroupsToFieldIds, snakeCase } from '../../lib/util'
import { FieldValuesMultipleSelect } from '../forms'
import { CheckmarkIcon } from '../icons'

export const CertificationsPanel = () => {
  const [userProfile, setUserProfile] = useUserProfileState()
  const group = userProfile?.groups.find(g => g !== 'ace_compass_access')

  const certificationFromGroup = () => {
    switch (group) {
      case 'ace_certified_personal_trainer': return 'Personal Trainer'
      case 'ace_certified_group_fitness_instructor': return 'Group Fitness Instructor'
      case 'ace_certified_health_coach': return 'Health Coach'
      case 'ace_certified_medical_exercise_specialist': return 'Medical Exercise Specialist'
      default: return null
    }
  }

  useEffect(() => {
    const certifications = aceGroupsToFieldIds(userProfile?.groups)
    if (certifications) setUserProfile({ ...userProfile, certifications })
  }, [])

  const analytics = useAnalytics()
  useAnalyticsPageView({ title: 'Certifications', type: 'Onboarding' })

  return (
    <>
      <header className='onboard__panel-header'>
        <h2>Current and Future Certification(s)</h2>
        <p>
          Ready to explore with ACE&reg; Pro Compass? Let&apos;s set you up!
        </p>
        {group && (
          <p>
            It looks like you&apos;ve completed the {certificationFromGroup()} Certification. Do you hold any other current certifications that we should know about?
          </p>
        )}
      </header>
      <section className='onboard__panel-content'>
        <FieldValuesMultipleSelect
          values={certifications.values}
          selected={userProfile?.certifications ?? []}
          onSelect={certifications => {
            setUserProfile({ ...userProfile, certifications })
            analytics('link', {
              'event_type': 'onboarding',
              [snakeCase('certifications')]: certifications,
            })
          }}
          disabledIds={aceGroupsToFieldIds(userProfile?.groups)}
          baseClassName={'onboard-options-grid'}
          iconOn={<CheckmarkIcon />}
          iconOff={<CheckmarkIcon />} />
      </section>
    </>
  )
}
