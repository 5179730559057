interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const SettingsIcon = ({ width = 20, height = 20 , classNames = 'icon' }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      className={classNames}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="currentColor" transform="translate(-242 -115)">
          <g transform="translate(242 115)">
            <rect width="2" height="20" x="16" y="0" rx="1"></rect>
            <rect width="2" height="20" x="9" y="0" rx="1"></rect>
            <rect width="2" height="20" x="2" y="0" rx="1"></rect>
            <rect width="6" height="4" x="0" y="4" rx="2"></rect>
            <rect width="6" height="4" x="7" y="12" rx="2"></rect>
            <rect width="6" height="4" x="14" y="7" rx="2"></rect>
          </g>
        </g>
      </g>
    </svg>
  );
}
