interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const MagnifyingGlassIcon = ({ width = 32, height = 32 , classNames = 'icon' }: Props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      className={classNames}
    >
      <defs>
        <filter id='filter-1' colorInterpolationFilters='auto'>
          <feColorMatrix
            in='SourceGraphic'
            values='0 0 0 0 0.321569 0 0 0 0 0.509804 0 0 0 0 0.870588 0 0 0 1.000000 0'
          ></feColorMatrix>
        </filter>
      </defs>
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g filter='url(#filter-1)' transform='translate(-149 -315)'>
          <g transform='translate(149 315)'>
            <path
              fill='#000'
              d='M2.667 12.67A9.99 9.99 0 0112.67 2.667 9.99 9.99 0 0122.672 12.67 9.99 9.99 0 0112.67 22.672 9.99 9.99 0 012.667 12.67m28.94 17.07l-9.068-9.135a12.627 12.627 0 002.8-7.935C25.34 5.668 19.673 0 12.67 0 5.67 0 0 5.668 0 12.67s5.668 12.67 12.67 12.67c3 0 5.801-1.067 7.935-2.801l9.07 9.069c.6.6 1.466.4 1.866 0 .6-.534.6-1.334.067-1.867'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
