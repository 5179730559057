interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const MobileMenuIcon = ({ width = 21, height = 11, classNames = 'icon' }: Props) => {

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={width}
      height={height}
      viewBox='0 0 21 11'
      className={classNames}
    >
      <g
        fill='currentColor'
        fillRule='evenodd'
        stroke='none'
        strokeLinecap='square'
        strokeWidth='1'
      >
        <g stroke='#1F2325' strokeWidth='2' transform='translate(-290 -19)'>
          <g transform='translate(291.5 20)'>
            <path d='M0 0.5L18 0.5'></path>
            <path d='M0 4.5L18 4.5'></path>
            <path d='M0 8.5L18 8.5'></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
