interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const TargetIcon = ({
  width = 70,
  height = 80,
  classNames = 'icon',
}: Props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 70 80'
      className={classNames}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="currentColor" transform="translate(-451 -4048)">
          <g transform="translate(451 4048)">
            <path d="M70 35.079a34.922 34.922 0 00-3.693-15.647l-.672.673a6.26 6.26 0 01-3.105 1.682 30.86 30.86 0 013.021 13.291c0 16.909-13.765 30.705-30.635 30.705S4.364 51.987 4.364 35.078C4.364 18.17 18.13 4.374 35 4.374c6.546 0 12.59 2.103 17.542 5.552v4.459l-2.434 2.439c-4.113-3.45-9.4-5.468-15.108-5.468-13.093 0-23.753 10.684-23.753 23.807S21.907 58.885 35 58.885s23.753-10.684 23.753-23.806c0-4.207-1.091-8.076-2.938-11.525l-3.273 3.28c1.175 2.525 1.846 5.3 1.846 8.245 0 10.683-8.645 19.348-19.304 19.348-10.66 0-19.305-8.665-19.305-19.348 0-10.684 8.645-19.348 19.305-19.348 4.532 0 8.728 1.598 12.002 4.206L42.134 24.9c-2.015-1.43-4.449-2.272-7.05-2.272-6.883 0-12.422 5.552-12.422 12.45-.084 6.899 5.455 12.45 12.338 12.45 6.883 0 12.422-5.552 12.422-12.45 0-.925-.084-1.934-.336-2.775l-4.196 4.122c-.672 3.785-3.945 6.73-7.974 6.73-4.448 0-8.058-3.617-8.058-8.076 0-4.459 3.61-8.076 8.058-8.076 1.427 0 2.77.42 3.945 1.01l-5.455 5.467c-.84.841-.84 2.271 0 3.112.42.42 1.007.673 1.51.673.504 0 1.091-.252 1.51-.673l19.137-19.18h5.792c.42 0 .84-.168 1.175-.504l6.63-6.646c.504-.504.588-1.177.336-1.766a1.657 1.657 0 00-1.51-1.01h-5.54V1.935c0-.673-.42-1.262-1.007-1.514-.588-.253-1.343-.084-1.763.336l-5.203 5.215C48.933 2.187 42.218 0 35 0 15.696 0 0 15.73 0 35.079c0 13.039 7.218 24.48 17.794 30.536l-5.456 11.188c-.503 1.094-.083 2.44 1.008 2.945.335.168.671.252.923.252.84 0 1.595-.42 1.93-1.262l5.372-11.188c4.113 1.683 8.561 2.692 13.345 2.692 4.7 0 9.233-.925 13.262-2.607l5.54 11.02c.42.756 1.174 1.26 1.93 1.26.335 0 .671-.083.923-.252 1.091-.504 1.51-1.85 1.007-2.944L52.206 65.53A34.86 34.86 0 0070 35.078z"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
