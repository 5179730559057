interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const ExternalIcon = ({ width = 16, height = 17, classNames = 'icon' }: Props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 17"
      className={classNames}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="currentColor" transform="translate(-452 -990)">
          <g transform="translate(447.095 985.631)">
            <path
              d="M13.711 22.933c-.006.012-.019.019-.025.025-.17.17-.39.277-.61.308-.02 0-.038.007-.057.007-.038.006-.076.006-.114.006l-.107-.006c-.019 0-.038-.007-.057-.007a1.073 1.073 0 01-.61-.308l-.025-.025-6.763-6.763a1.1 1.1 0 01-.321-.78 1.1 1.1 0 01.321-.781 1.11 1.11 0 011.562 0l4.91 4.91V3.643c0-.604.498-1.102 1.103-1.102.302 0 .579.126.78.321.202.202.322.473.322.781V19.52l4.91-4.911a1.11 1.11 0 011.562 0c.41.434.41 1.133-.018 1.561l-6.763 6.763z"
              transform="rotate(-135 12.91 12.91)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
