import { Timestamp } from 'firebase/firestore'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import useUpdatePreviewRef from '../../lib/hooks/useUpdatePreviewRef'
import { ScoreAction, useUserContentCallback, useUserTopicCallback } from '../../lib/state'
import Custom404 from '../../pages/404'
import { Loading } from '../common'

interface Props {
  contentId: string
  previewRef: string
  topicIds: string[]
  children: any
}

export const ArticleWrapper = ({ contentId, previewRef, topicIds, children }: Props) => {
  const router = useRouter()
  const userContentCallback = useUserContentCallback()
  const userTopicCallback = useUserTopicCallback()
  useUpdatePreviewRef(previewRef, contentId)

  useEffect(() => {
    if (contentId) {
      userContentCallback({ viewed: Timestamp.now() }, [contentId])
    }
    if (topicIds && topicIds.length) {
      const action = router.query?.from === 'recommendation' ?
        ScoreAction.ViewRecommendation :
        ScoreAction.ViewContent
      userTopicCallback({ action }, topicIds)
    }
  }, [])

  if (router.isFallback) {
    return (
      <div className='loading loading--page'>
        <Loading />
      </div>
    )
  }

  if (!contentId) {
    return <Custom404 />
  }

  return children
}
