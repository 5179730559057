import { Dialog, Tab } from '@headlessui/react'
import { Timestamp } from 'firebase/firestore'
import Image from 'next/image'
import { useState } from 'react'
import { nameTopics } from '../../lib/name'
import { ScoreAction, useAnalytics, useUserContentCallback, useUserTopicCallback } from '../../lib/state'
import { ArrowLeftIcon, CloseIcon } from '../icons'
import { CertificationsPanel, ChallengesPanel, EmploymentTypePanel, ExperiencePanel, ProfessionalInterestPanel, TopicsPanel } from '../onboarding-panels'

interface Props {
  open: boolean
  onClose: () => void
  onFinish: () => void
}

export const OnboardingModal = ({ open, onClose, onFinish }: Props) => {
  const [index, setIndex] = useState(0)
  const [selectedTopicIds, setSelectedTopicIds] = useState([])
  const [selectedChallenges, setSelectedChallenges] = useState<{ [id: string]: string[] }>({})
  const userContentCallback = useUserContentCallback()
  const userTopicCallback = useUserTopicCallback()
  const analytics = useAnalytics()

  return (
    <Dialog open={open} onClose={onClose} className='takeover'>
      <Tab.Group onChange={setIndex} selectedIndex={index}>
        <div className='takeover__shell-inner'>
          <article className='takeover__shell-container'>
            <header className='takeover__shell-header'>
              <span className='takeover__header-section takeover__header-section--branding'>
                <span className='takeover__logo-desktop'>
                  <Image src="/assets/img/logo-primary.svg" alt="ACE PRO Compass" width={160} height={43} />
                </span>
                <span className='takeover__logo-mobile'>
                  <Image src="/assets/img/ace-circle-logo-icon.svg" alt="ACE PRO Compass" width={26} height={26} />
                </span>
              </span>
              <span className='takeover__header-section takeover__header-section--indicate'>
                <div className='takeover__indicators'>
                  <Tab.List className={'step-indicators'}>
                    <Tab className={'indicate'}><span className='indicate__sr-text'>Certifications</span></Tab>
                    <Tab className={'indicate'}><span className='indicate__sr-text'>Experience</span></Tab>
                    <Tab className={'indicate'}><span className='indicate__sr-text'>Professional Interest</span></Tab>
                    <Tab className={'indicate'}><span className='indicate__sr-text'>Employment Type</span></Tab>
                    <Tab className={'indicate'}><span className='indicate__sr-text'>Topics of Interest</span></Tab>
                    <Tab className={'indicate'}><span className='indicate__sr-text'>Select Challenges</span></Tab>
                  </Tab.List>
                </div>
              </span>
              <span className='takeover__header-section takeover__header-section--close'>
                <button className='takeover__x-btn' type='button' onClick={onClose}><CloseIcon width={24} height={24} /></button>
              </span>
            </header>
            <section className='takeover__shell-body'>
              <Dialog.Title className='sr-only'>Create your ACE Pro Compass Profile</Dialog.Title>
              <Dialog.Description></Dialog.Description>
              <Tab.Panels className={'onboard'}>
                <Tab.Panel className={'onboard__panel'}>
                  <CertificationsPanel />
                </Tab.Panel>
                <Tab.Panel className={'onboard__panel'}>
                  <ExperiencePanel />
                </Tab.Panel>
                <Tab.Panel className={'onboard__panel'}>
                  <ProfessionalInterestPanel />
                </Tab.Panel>
                <Tab.Panel className={'onboard__panel'}>
                  <EmploymentTypePanel />
                </Tab.Panel>
                <Tab.Panel className={'onboard__panel'}>
                  <TopicsPanel
                    selectedIds={selectedTopicIds}
                    onChange={setSelectedTopicIds} />
                </Tab.Panel>
                <Tab.Panel className={'onboard__panel'}>
                  <ChallengesPanel
                    selectedIds={Object.keys(selectedChallenges)}
                    onSelect={(id, topicIds) => {
                      setSelectedChallenges({ ...selectedChallenges, [id]: topicIds })
                    }}
                    onUnselect={id => {
                      const { [id]: value, ...remaining } = selectedChallenges
                      setSelectedChallenges(remaining)
                    }}
                    selectedTopicIds={selectedTopicIds} />
                </Tab.Panel>
              </Tab.Panels>
            </section>
            <footer className={`takeover__shell-footer${index > 0 ? ' takeover__shell-footer--space-between' : ''}`}>
              {index > 0 && (
                <button
                  className='takeover__button-prev'
                  onClick={() => setIndex(index - 1)}>
                  <ArrowLeftIcon />  Previous
                </button>
              )}
              {index < 5 && (
                <button
                  className='takeover__button-next'
                  onClick={() => setIndex(index + 1)}>
                  Next <ArrowLeftIcon />
                </button>
              )}
              {index === 5 && (
                <button
                  className='takeover__button-finish'
                  onClick={() => {
                    userTopicCallback({ action: ScoreAction.OnboardTopic, explicit: true }, selectedTopicIds)
                    userContentCallback({ enrolled: Timestamp.now() }, Object.keys(selectedChallenges))
                    userTopicCallback({ action: ScoreAction.OnboardChallenge }, Object.values(selectedChallenges).flatMap(topicIds => topicIds))
                    analytics('link', {
                      'event_type': 'onboarding',
                      'content_ids': Object.keys(selectedChallenges),
                      'topic_ids': selectedTopicIds,
                      'interest_topics': nameTopics(selectedTopicIds),
                    })
                    Object.keys(selectedChallenges).forEach(id => {
                      analytics('link', {
                        'event_type': 'enroll_challenge',
                        'page_type': 'Onboarding',
                        'content_id': id,
                      })
                    })
                    onFinish()
                  }}>
                  Finish
                </button>
              )}
            </footer>
          </article>
        </div>
      </Tab.Group>
    </Dialog>
  )
}
