import { Tab } from '@headlessui/react'
import { useState } from 'react'
import Link from 'next/link'
import { useCompletedChallenges, useIncompletedChallenges, useSearchResponse } from '../../lib/state'
import { DocumentType } from '../../lib/prismic'
import { CardSlider } from '../cards'
import { TopicsList } from '../explore'
import { ArrowLeftIcon, MtnFlagIcon } from '../icons'
import { LayoutRow } from '../layout'

export const MyChallenges = () => {
  const [index, setIndex] = useState(0)
  const [incompletedChallenges] = useIncompletedChallenges()
  const [completedChallenges] = useCompletedChallenges()

  const [allChallenges] = useSearchResponse({ types: [DocumentType.Challenge] })
  const challengeTopicIds = []
  if (allChallenges) {
    allChallenges.hits.forEach(hit => {
      hit.topic_ids.forEach(challengeTopicId => challengeTopicIds.push(challengeTopicId))
    })
  }

  const NoChallengeData = () => (
    <div className='my__topics my__topics--dark'>
      <p>You do not have any active Challenges. Explore topics of interest and find Challenges to accept.</p>
      <TopicsList topicIds={challengeTopicIds} />
    </div>
  )

  return (
    <article className='my my-challenges'>
      <LayoutRow classNames='bg-mtn bg-mtn--purple-tinted-valign-bottom'>
        <section className='my__wrap'>
          <header className='my__header'>
            <span className='my__header-branding'>
              <MtnFlagIcon width={80} height={82.9} />
            </span>
            <span className='my__header-content'>
              <h2>My Challenges</h2>
              <p>View your Challenges in progress or revisit completed ones here.</p>
            </span>
          </header>

          <Tab.Group onChange={setIndex} selectedIndex={index} manual>
            <Tab.List className={'my-challenges__tablist'}>
              <div className='my-challenges__tablist-buttons'>
                <Tab className={'my-challenges__tablist-button'}>Active ({incompletedChallenges?.length})</Tab>
                <Tab className={'my-challenges__tablist-button'}>Completed ({completedChallenges?.length})</Tab>
              </div>
              <div className='my-challenges__tablist-link'>
                <Link href={{
                  pathname: '/explore',
                  query: { y: DocumentType.Challenge, c: '1' },
                }}>
                  <a className='arrow-link'>Explore More Challenges <ArrowLeftIcon /></a>
                </Link>
              </div>
            </Tab.List>
            <Tab.Panels className={'my-challenges__tabpanels'}>
              <Tab.Panel className={'my-challenges__tabpanel'}>
                {incompletedChallenges?.length ? (
                  <CardSlider cards={incompletedChallenges} theme={'dark'} />
                ) :
                  <NoChallengeData />
                }
              </Tab.Panel>
              <Tab.Panel className={'my-challenges__tabpanel'}>
                {completedChallenges?.length ? (
                  <CardSlider cards={completedChallenges} theme={'dark'} />
                ) : (
                  <NoChallengeData />
                )}
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </section>
      </LayoutRow>
    </article>
  )
}
