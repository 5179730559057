interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const ResourceIcon = ({ width = 22, height = 22 , classNames = 'icon' }: Props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22 22"
      className={classNames}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="currentColor" transform="translate(-128 -628)">
          <g transform="translate(128 628)">
            <path d="M7.381 7.305l2.912-5.26a.808.808 0 011.414 0l2.911 5.258a.815.815 0 01-.01.807.808.808 0 01-.696.4H8.088a.81.81 0 01-.696-.4.815.815 0 01-.011-.805zm.707 2.829h5.824c.86 0 1.655-.457 2.091-1.201a2.445 2.445 0 00.03-2.42l-2.912-5.26a2.419 2.419 0 00-4.241 0l-2.912 5.26a2.445 2.445 0 00.027 2.42 2.425 2.425 0 002.093 1.201zm0 8.944a.814.814 0 01-.809.811H2.426a.81.81 0 01-.808-.812v-4.883a.81.81 0 01.808-.812h4.865a.813.813 0 01.809.812l-.012 4.884zm-.809-7.32H2.426c-.643 0-1.26.256-1.715.713A2.44 2.44 0 000 14.194v4.883c0 .646.255 1.266.71 1.723a2.422 2.422 0 001.716.713h4.865c.643 0 1.26-.257 1.716-.713.454-.457.71-1.077.71-1.723v-4.883a2.44 2.44 0 00-.71-1.723 2.423 2.423 0 00-1.716-.713h-.012zm9.383 8.607a3.714 3.714 0 01-2.631-1.094 3.743 3.743 0 010-5.282 3.714 3.714 0 015.262 0 3.743 3.743 0 010 5.282 3.714 3.714 0 01-2.631 1.094zm0-9.083a5.327 5.327 0 00-3.775 1.57 5.37 5.37 0 00-1.563 3.789 5.37 5.37 0 001.563 3.79 5.327 5.327 0 007.55 0A5.37 5.37 0 0022 16.64a5.37 5.37 0 00-1.563-3.79 5.327 5.327 0 00-3.775-1.57z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
