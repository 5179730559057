import { RadioGroup } from '@headlessui/react'
import { Value } from '../../lib/fields'

interface Props {
  values: Value[]
  selected: string
  onSelect(selected: string): void
  baseClassName?: string
  iconOn?: any
  iconOff?: any
}

export const FieldValuesSelect = ({ values, selected, onSelect, baseClassName = 'field-values-select', iconOff = '☐', iconOn = '✔️' }: Props) => {
  return (
    <RadioGroup className={baseClassName} value={selected} onChange={onSelect}>
      {values.map(({ id, name, help }) =>
        <RadioGroup.Option key={id} value={id} as='button' className={`${baseClassName}__button`}>
          {({ checked }) => (
            <>
              {iconOn && iconOff &&(
              <span className={`${baseClassName}__button-icon`}>
                {checked ? iconOn : iconOff}
              </span>
              )}
              <span className={`${baseClassName}__button-text`}>
                {name && (
                  <span className={`${baseClassName}__button-text-label`}>{name}</span>
                )}
                {help && (
                  <span className={`${baseClassName}__button-text-desc`}>{help}</span>
                )}
              </span>
            </>
          )}
        </RadioGroup.Option>
      )}
    </RadioGroup>
  )
}
