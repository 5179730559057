import Image from 'next/image'
import { RichText } from 'prismic-reactjs'

interface Props {
  prismicContributor: any
  classNames?: string
}

export const ContributorWidget = ({ prismicContributor, classNames }: Props) => {
  const { data } = prismicContributor

  return (
    <figure className={`author${(classNames ? ' ' + classNames : '')}`}>
      <div className='author__avatar'>
        <Image
          width={80}
          height={80}
          layout={'responsive'}
          src={data?.profile_photo.url || '/assets/img/avatar-placeholder@2x.jpg'}
          alt={data?.profile_photo.alt} />
      </div>
      <figcaption className='author__byline'>
        <span className='author__by'>by</span>
        <address className='author__name'>{RichText.asText(data?.name) ?? 'American Council on Exercise'}</address>
        <span className='author__role'>{RichText.asText(data?.job_title)}</span>
      </figcaption>
    </figure>
  )
}
