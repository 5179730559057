import { useBestRelatedChallengeInfo, useSearchResponse, useUserContentState } from '../../lib/state'
import { CardSlider } from '../cards'
import { LayoutRow } from '../layout'

interface Props {
  contentId: string
}

export const UpNext = ({ contentId }: Props) => {
  const [challengeInfo] = useBestRelatedChallengeInfo(contentId)
  const [userContent] = useUserContentState(challengeInfo?.id)
  const [response, loading, error] = useSearchResponse({
    includeIds: challengeInfo?.taskIds.filter(id => id !== contentId) ?? [],
    excludeIds: userContent?.completions ?? [],
  })

  return !challengeInfo || !response || response.nbHits === 0 ? null : (
    <article className='my my--theme-dark'>
      <LayoutRow classNames='bg-mtn bg-mtn--purple-tinted-valign-bottom'>
        <section className='my__wrap'>
          <header className='my__header'>
            <span className='my__header-content'>
              <span className='my__header-content-headline'>
                <h2>Up next in this Challenge</h2>
              </span>
            </span>
          </header>
          <CardSlider cards={response.hits} theme={'light'} />
        </section>
      </LayoutRow>
    </article>
  )
}
