import { Loadable, SetterOrUpdater } from 'recoil'

export const expandLoadableValue = <T>(loadable: Loadable<T>): [T, boolean, any] => ([
  loadable.valueMaybe(),
  loadable.state === 'loading',
  loadable.errorMaybe()
])

export const expandLoadableState = <T>(params: [Loadable<T>, SetterOrUpdater<T>]): [T, SetterOrUpdater<T>, boolean, any] => {
  const loadable = params[0]
  const setter = params[1]
  return [
    loadable.valueMaybe(),
    setter,
    loadable.state === 'loading',
    loadable.errorMaybe()
  ]
}
