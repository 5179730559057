import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useRecoilCallback } from 'recoil';
import { nameContent, nameContentContributor, nameContentContributors, nameContentDuration, nameContentDurations, nameContents, nameContentType, nameContentTypes, nameField, nameFields, nameTopic, nameTopics } from '../name';
import { userChallengesCompleted, userContentsCompleted } from './user-contents-state';
import { UserProfile, userProfileState } from './user-profile-state';
import { userState } from './user-state';
import { userTopicsExplicit, userTopicsSorted } from './user-topics-state';

declare const utag: any;
let pageParams = {}

export const useAnalytics = () => useRecoilCallback(({ snapshot }) => async (type: 'view' | 'link', params: any) => {
  const release = snapshot.retain()
  const user = await snapshot.getPromise(userState)
  let userProfile: UserProfile
  let didOnboard: boolean
  let contentsCompleted: number
  let challengesCompleted: number
  let topTopics: string[]
  let likedTopics: string[]
  if (user) {
    userProfile = await snapshot.getPromise(userProfileState)
    didOnboard = !!(userProfile.certifications && userProfile.employmentType && userProfile.experience && userProfile.professionalInterest)
    contentsCompleted = (await snapshot.getPromise(userContentsCompleted)).length
    challengesCompleted = (await snapshot.getPromise(userChallengesCompleted)).length
    topTopics = await snapshot.getPromise(userTopicsSorted)
    likedTopics = await snapshot.getPromise(userTopicsExplicit)
  }
  release()
  const userParams = {
    'account_type': 'compass',
    'country_code': '',
    'user_is_logged_in': !!user,
    'customer_id': user?.uid,
    'customer_email': user?.email,
    'customer_first_name': userProfile?.name.first,
    'customer_last_name': userProfile?.name.last,
    'is_ace_pro': userProfile?.pro,
    'is_ace_gfi': userProfile?.groups.includes('ace_certified_group_fitness_instructor'),
    'is_ace_hc': userProfile?.groups.includes('ace_certified_health_coach'),
    'is_ace_mse': userProfile?.groups.includes('ace_certified_medical_exercise_specialist'),
    'is_ace_pft': userProfile?.groups.includes('ace_certified_personal_trainer'),
    'completed_onboarding': didOnboard,
    'challenge_completed_count': challengesCompleted,
    'content_completed_count': contentsCompleted,
  }
  const topicParams = type === 'view'
    ? { 'top_topics': nameTopics(topTopics), 'liked_topics': nameTopics(likedTopics) }
    : {}
  //replace fields
  if (params.certifications) params.certifications = nameFields(params.certifications)
  if (params.experience) params.experience = nameField(params.experience)
  if (params.professional_interest) params.professional_interest = nameField(params.professional_interest)
  if (params.employment_type) params.employment_type = nameField(params.employment_type)
  // replace topic ids
  if (params.topic_id) params.topic = nameTopic(params.topic_id)
  if (params.topic_ids) params.topics = nameTopics(params.topic_ids)
  // replace content ids
  if (params.content_id) {
    params.content = nameContent(params.content_id)
    params.content_author = nameContentContributor(params.content_id)
    params.task_duration = nameContentDuration(params.content_id)
    params.content_type = nameContentType(params.content_id)
  }
  if (params.content_ids) {
    params.contents = nameContents(params.content_ids)
    params.content_authors = nameContentContributors(params.content_ids)
    params.task_durations = nameContentDurations(params.content_ids)
    params.content_types = nameContentTypes(params.content_ids)
  }
  if (params.challenge_id) {
    params.challenge = nameContent(params.challenge_id)
    params.challenge_author = nameContentContributor(params.challenge_id)
    params.challenge_duration = nameContentDuration(params.challenge_id)
  }
  console.log('Analytics', type, { ...userParams, ...topicParams, ...pageParams, ...params })
  if ((window as any).utag) utag.track(type, { ...userParams, ...topicParams, ...pageParams, ...params })
})

export const useAnalyticsPageView = ({ title, type, topic_ids, ...rest }: any) => {
  const router = useRouter()
  const analytics = useAnalytics()

  useEffect(() => {
    pageParams = {
      'page_name': title,
      'page_type': type,
      'page': router.asPath,
      'page_url': router.asPath,
      'page_path': router.asPath,
      ...rest,
    }
    analytics('view', {
      'event_type': 'page_view',
      topic_ids,
    })
  }, [])
}
