import { CardThumbnail } from '.'
import { DocumentType } from '../../lib/prismic'
import { ArticlePreheader, ResourceLink, ResourceLinks } from '../articles'
import { ContentDuration, ContentLink, PinButton, ViewedAgo } from '../content'

interface Props {
  data: any
  from?: string
  onClick?: () => void
  children?: any
}

export const Card = ({ data, from, onClick, children }: Props) => {
  const truncateAbstract = (str: string): string => str.length > 150 ? str.substring(0, 149).trimEnd() + '…' : str

  const allLinks: ResourceLink[] = data.resource_links.map(resource_link => ({ ...resource_link } as ResourceLink))

  return (
    <>
      <article className='card'>
        {data.type !== DocumentType.CheckboxTask &&
          <>
            <CardThumbnail type={data.type} data={data} from={from} onClick={onClick} />
            <section className='card-preheader'>
              <ContentLink type={data.type} uid={data.uid} from={from}>
                <a onClick={onClick} tabIndex={-1}>
                  <ArticlePreheader type={data.type}>
                    <ContentDuration minutes={data.consumption_time} type={data.type} />
                    <ViewedAgo contentId={data.objectID} />
                  </ArticlePreheader>
                </a>
              </ContentLink>
            </section>
          </>
        }
        <section className='card-body'>
          <header className='card-body__header'>
            <h3 className='card-body__title'>
              {(data.type !== DocumentType.CheckboxTask) ?
                <ContentLink type={data.type} uid={data.uid} from={from}>
                  <a onClick={onClick}>
                    {data.title}
                  </a>
                </ContentLink>
                :
                <>{data.title}</>
              }
            </h3>
            <PinButton
              className='card-body__pin-button'
              contentId={data.objectID}
              topicIds={data.topic_ids} />
          </header>

          {(data.abstract) &&
            <p className='card-body__desc'>
              <ContentLink type={data.type} uid={data.uid} from={from}>
                <a onClick={onClick} tabIndex={-1}>
                  {truncateAbstract(data.abstract)}
                </a>
              </ContentLink>
            </p>
          }

          {(data.type === DocumentType.CheckboxTask) &&
            <ResourceLinks links={allLinks} />
          }

        </section>
        <footer className='card-footer'>
          {children}
        </footer>
      </article>
    </>
  )
}
