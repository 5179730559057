import Image from 'next/image'
import { DocumentType } from '../../lib/prismic'
import { Tag } from '../common'
import { ContentLink, NewTag } from '../content'

interface Props {
  data: any
  type: DocumentType
  from?: string
  onClick?: () => void
}

export const CardThumbnail = ({ data, type, from, onClick }: Props) => {

  const thumbnailUrl = (): string => {
    switch (type) {
      case DocumentType.Video: return '/assets/img/card-thumb-video@2x.jpg'
      case DocumentType.Audio: return '/assets/img/card-thumb-audio@2x.jpg'
      default: return data.card_thumbnail_url ?
        data.card_thumbnail_url :
        '/assets/img/dev-only/card/placeholder-card-thumbnail.png'
    }
  }

  return (
    <ContentLink type={data.type} uid={data.uid} from={from}>
      <a className='card-thumb' onClick={onClick} tabIndex={-1}>
        <span className='card-thumb__tags'>
          {data.sponsored && <Tag type='sponsored' content='Sponsored' />}
          <NewTag contentId={data.objectID} published={data.first_publication_timestamp} />
        </span>
        <span className='card-thumb__img-wrap'>
          <Image
            layout={'fill'}
            objectFit={'cover'}
            objectPosition={'center'}
            src={thumbnailUrl()}
            alt={''} />
        </span>
      </a>
    </ContentLink>
  )
}
