interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const AudioContentIcon = ({ width = 22, height = 22 , classNames = 'icon' }: Props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={height}
      height={width}
      viewBox="0 0 22 22"
      className={classNames}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-129 -710)">
          <g fill="currentColor" transform="translate(129 710)">
            <path d="M18.8 10.29a7.834 7.834 0 01-7.015 6.936v3.18h2.354a.784.784 0 110 1.57H7.861a.784.784 0 110-1.57h2.354v-3.18A7.834 7.834 0 013.2 10.289a.785.785 0 011.56-.173 6.27 6.27 0 006.24 5.58 6.27 6.27 0 006.24-5.58.792.792 0 01.866-.693c.431.048.742.436.694.866"></path>
            <path d="M14.14 4.709v4.709a3.143 3.143 0 01-3.14 3.14 3.143 3.143 0 01-3.14-3.14V4.709A3.143 3.143 0 0111 1.569a3.143 3.143 0 013.14 3.14m-7.849 0v4.709A4.714 4.714 0 0011 14.127a4.714 4.714 0 004.709-4.709V4.709A4.714 4.714 0 0011 0a4.714 4.714 0 00-4.709 4.709"></path>
            <path d="M18.322 2.745a3.93 3.93 0 01.002 3.927.785.785 0 01-1.36-.785 2.355 2.355 0 000-2.355.785.785 0 111.358-.787"></path>
            <path d="M21.108 1.17c.6 1.038.892 2.196.892 3.539 0 1.245-.314 2.497-.885 3.526a.798.798 0 01-1.394-.773c.441-.797.685-1.774.685-2.753 0-1.055-.222-1.952-.678-2.74a.797.797 0 111.38-.799"></path>
            <path d="M3.677 6.672a3.93 3.93 0 01.001-3.927.785.785 0 111.359.787 2.35 2.35 0 000 2.355.784.784 0 11-1.36.785"></path>
            <path d="M2.28 7.462a.798.798 0 01-1.395.773C.315 7.205 0 5.954 0 4.709 0 3.365.291 2.208.892 1.17a.797.797 0 111.38.799c-.456.788-.678 1.685-.678 2.74 0 .979.244 1.956.685 2.753"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
