interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const DownloadIcon = ({ width = 20, height = 21 , classNames = 'icon' }: Props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 21"
      className={classNames}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="currentColor" transform="translate(-450 -869)">
          <g transform="translate(450 869)">
            <g transform="translate(0 13.577)">
              <path d="M20 .951v4.427c0 .088-.013.17-.032.246a.95.95 0 01-.925.762H.95a.948.948 0 01-.938-.844C0 5.485 0 5.435 0 5.372V.945C0 .422.428 0 .945 0a.948.948 0 01.944.945V4.49h16.228V.945c0-.523.428-.945.945-.945.258 0 .497.107.667.277.164.177.271.416.271.674z"></path>
            </g>
            <path d="M15.447 10.094l-4.641 4.641c-.006.013-.019.02-.025.026-.17.17-.39.277-.611.308-.019 0-.038.006-.057.006-.037.007-.075.007-.113.007l-.107-.007c-.019 0-.038-.006-.057-.006a1.073 1.073 0 01-.61-.308l-.026-.026-4.64-4.64a1.1 1.1 0 01-.322-.781 1.1 1.1 0 01.321-.781 1.11 1.11 0 011.562 0l2.79 2.79V1.101C8.91.497 9.407 0 10.011 0c.303 0 .58.126.781.321.202.202.321.472.321.781v10.22l2.79-2.79a1.11 1.11 0 011.562 0c.41.435.41 1.134-.019 1.562z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
