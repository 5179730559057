import { Dialog, Tab } from '@headlessui/react'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import { useUserContentsViewedNoSentiment, useUserTopicsNotExplicit } from '../../lib/state'
import { ArrowLeftIcon, CloseIcon } from '../icons'
import { ContentPanel, TopicsPanel } from '../sentiment-panels'

interface Props {
  open: boolean
  onClose: () => void
}

export const SentimentModal = ({ open, onClose }: Props) => {
  const [panels, setPanels] = useState([])
  const [index, setIndex] = useState(0)
  const [userContentsViewedNoSentiment] = useUserContentsViewedNoSentiment()
  const [userTopicsNotExplicit] = useUserTopicsNotExplicit()

  useEffect(() => {
    if (panels.length) return
    if (!userContentsViewedNoSentiment) return
    if (!userTopicsNotExplicit) return
    if (
      !userContentsViewedNoSentiment.length &&
      userTopicsNotExplicit.length < 2
    )
      return

    setPanels([
      ...userContentsViewedNoSentiment.map(contentId => (
        { Panel: ContentPanel, props: { contentId } }
      )),
      ...(!userTopicsNotExplicit.length ? [] : [{ Panel: TopicsPanel, props: { topicIds: userTopicsNotExplicit } }]),
    ])
  }, [userContentsViewedNoSentiment, userTopicsNotExplicit])

  return (
    <Dialog open={open} onClose={onClose} className='takeover'>
      <Tab.Group onChange={setIndex} selectedIndex={index}>
        <div className='takeover__shell-inner'>
          <article className='takeover__shell-container'>
            <header className='takeover__shell-header'>
              <span className='takeover__header-section takeover__header-section--branding'>
                <span className='takeover__logo-desktop'>
                  <Image src="/assets/img/logo-primary.svg" alt="ACE PRO Compass" width={160} height={43} />
                </span>
                <span className='takeover__logo-mobile'>
                  <Image src="/assets/img/ace-circle-logo-icon.svg" alt="ACE PRO Compass" width={26} height={26} />
                </span>
              </span>
              <span className='takeover__header-section takeover__header-section--indicate'>
                <div className='takeover__indicators'>
                  <Tab.List className={'step-indicators'}>
                    {panels.map((_, index) => (
                      <Tab className={'indicate'} key={index}>
                        <span className='indicate__sr-text'>Sentiment Panel</span>
                      </Tab>
                    ))}
                  </Tab.List>
                </div>
              </span>
              <span className='takeover__header-section takeover__header-section--close'>
                <button className='takeover__x-btn' type='button' onClick={onClose}><CloseIcon width={24} height={24} /></button>
              </span>
            </header>
            <section className='takeover__shell-body'>
              <Dialog.Title className='sr-only'>Content Feedback</Dialog.Title>
              <Dialog.Description></Dialog.Description>
              <Tab.Panels className={'onboard'}>
                {panels.map(({ Panel, props }, index) => (
                  <Tab.Panel className={'onboard__panel'} key={index}>
                    <Panel {...props} />
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </section>
            <footer className={`takeover__shell-footer${index > 0 ? ' takeover__shell-footer--space-between' : ''}`}>
              {index > 0 && (
                <button
                  className='takeover__button-prev'
                  onClick={() => setIndex(index - 1)}>
                  <ArrowLeftIcon />  Previous
                </button>
              )}
              {index < panels.length - 1 && (
                <button
                  className='takeover__button-next'
                  onClick={() => setIndex(index + 1)}>
                  Next <ArrowLeftIcon />
                </button>
              )}
              {index === panels.length - 1 && (
                <button
                  className='takeover__button-finish'
                  onClick={() => onClose()}>
                  Finish
                </button>
              )}
            </footer>
          </article>
        </div>
      </Tab.Group>
    </Dialog>
  )
}
