import Slider from 'react-slick'
import { useAnalytics } from '../../lib/state'
import { Card, CardChallengeRelated } from '../cards'

interface Props {
  cards: any,
  theme?: 'dark' | 'light'
  from?: string
}

export const CardSlider = ({ cards, theme = 'light', from }: Props) => {
  const analytics = useAnalytics()

  const slickSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    dots: true,
    arrows: false,
    mobileFirst: true,
    // adaptiveHeight: true,
    appendDots: dots => <ul>{dots}</ul>,
    customPaging: i => (
      <button><span>{i}</span></button>
    ),
    responsive: [
      {
        breakpoint: 719,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 99999,
        settings: {
          unslick: true,
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      }
    ]
  };

  return (
    <Slider {...slickSettings} className={`card-slider card-slider--${theme}`}>
      {cards.map(hit => (
        <Card
          data={hit}
          from={from}
          key={hit.objectID}
          onClick={() => {
            analytics('link', {
              'event_type': 'select_content',
              'content_id': hit.objectID,
              'topic_ids': hit.topic_ids,
            })
          }}>
          <CardChallengeRelated contentId={hit.objectID} />
        </Card>
      ))}
    </Slider>
  )
}
