interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const MediaIcon = ({
  width = 86,
  height = 84,
  classNames = 'icon',
}: Props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 86 84'
      className={classNames}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#5282DE" transform="translate(-121 -3944)">
          <g transform="translate(121 3944)">
            <path d="M34.627 65.124h-23.23c-.08-.01-.159-.01-.238-.01 0 .01 0 .01-.008.01-1.283.099-2.24 1.242-2.144 2.556.088 1.269 1.142 2.24 2.39 2.205h23.23c1.283.027 2.346-1.008 2.372-2.331.035-1.314-.984-2.403-2.267-2.43h-.105"></path>
            <path d="M34.723 56.12H11.374c-1.283-.028-2.347 1.016-2.373 2.33-.035 1.314.984 2.403 2.268 2.43h23.348c1.284.027 2.347-1.008 2.382-2.33.027-1.315-.993-2.404-2.276-2.43"></path>
            <path d="M11.208 47.114c-1.282.072-2.275 1.197-2.204 2.51.07 1.315 1.159 2.323 2.44 2.26h23.229c1.282 0 2.327-1.071 2.327-2.385 0-1.314-1.045-2.385-2.327-2.385H11.208"></path>
            <path d="M10.462 41.883H30v-4.76H10.462a1.103 1.103 0 00-.244 0 2.39 2.39 0 00-2.211 2.555 2.385 2.385 0 002.455 2.205"></path>
            <path d="M55.387 74.774c0 2.59-2.014 4.606-4.595 4.615h-.026c-2.599 0-4.613-2.016-4.613-4.615V58.617h9.234v16.157zm2.302-20.772H46.153v-6.9H41.54v8.775c-.043.27-.043.533 0 .803v18.094c0 1.683.489 3.254 1.291 4.615H9.234c-2.598 0-4.613-2.016-4.613-4.615V28.615h25.802V24H2.319A2.308 2.308 0 000 26.295V74.774c0 5.077 4.16 9.22 9.234 9.22h41.532c.279.018.567-.008.828-.086C56.277 83.48 60 79.563 60 74.774V56.305a2.308 2.308 0 00-2.31-2.303z"></path>
            <path d="M78.905 37.201h-8.536v.603c0 .63-.577 1.198-1.217 1.198H64.88c-.64 0-1.217-.568-1.217-1.198v-.603H41.102c-.64.026-1.243-.515-1.279-1.145-.027-.63.524-1.224 1.164-1.258H63.663v-.595c0-.594.506-1.145 1.101-1.206h4.388c.64 0 1.217.577 1.217 1.206v.595h8.536c.648-.01 1.235.568 1.235 1.206 0 .63-.587 1.206-1.235 1.197m-39.02-9.58V7.22c0-1.311 1.11-2.404 2.434-2.404h35.37c1.331 0 2.433 1.093 2.433 2.404v20.401c0 1.303-1.102 2.395-2.434 2.395H42.32c-.293 0-.489-.21-.755-.306-.72-.27-1.208-.769-1.43-1.494-.062-.219-.249-.368-.249-.595M81.348 0H38.659C36.617 0 35 1.582 35 3.601v34.797c0 1.12.533 2.072 1.323 2.728.64.524 1.43.874 2.336.874H81.348c2.043 0 3.66-1.582 3.66-3.601V3.6C85.007 1.582 83.39 0 81.347 0"></path>
            <path d="M54.72 25.872l2.742-1.57 9.994-5.723c.725-.417.725-1.713 0-2.13L54.721 9.102c-.793-.372-1.704.338-1.721 1.083V24.808c0 .843 1.02 1.473 1.72 1.064"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
