import Prismic from "@prismicio/client"
import { QueryOptions } from "@prismicio/client/types/ResolvedApi"
import Link from 'next/link'
import {
  apiEndpoint,
  accessToken,
  documentTypes,
  linkResolver,
  routeResolver
} from '../config/prismicConfiguration'

export const enum DocumentType {
  Advertisement = "advertisement_v1",
  Article = "article_v4",
  Audio = "audio_v1",
  Challenge = "challenge_v1",
  CheckboxTask = "checkbox_task_v1",
  CourseResource = "course_resource_v1",
  PartnerResource = "partner_resource_v1",
  Resource = "resource_v1",
  Video = "video_v1",
}

export const getRoute = (type: DocumentType | string): string => {
  return documentTypes[type]?.route ?? null
}

export const getHumanizedName = (type: DocumentType | string): string => {
  return documentTypes[type]?.humanized_name ?? null
}


export const defaultOptions = {
  fetchLinks: [
    // this gets related Contributor document
    "contributor_v1.uid",
    "contributor_v1.name",
    "contributor_v1.job_title",
    "contributor_v1.profile_photo",
    "contributor_v1.bio_text",
  ],
};

// -- @prismicio/client initialisation
// Initialises the Prismic Client that's used for querying the API and passes it any query options.
export const Client = (req = null) => (
  Prismic.client(apiEndpoint, createClientOptions(req, accessToken, routeResolver))
);

// Options to be passed to the Client
const createClientOptions = (req = null, prismicAccessToken = null, routes = null) => {
  const reqOption = req ? { req } : {}
  const accessTokenOption = prismicAccessToken ? { accessToken: prismicAccessToken } : {}
  const routesOption = routes ? { routes: routeResolver.routes } : {}
  return {
    ...reqOption,
    ...accessTokenOption,
    ...routesOption,
  }
}

const predicates = Prismic.predicates;

const getByUID = <T,>(
  type: DocumentType,
  uid: string,
  options: QueryOptions = defaultOptions
) => Client().getByUID<T>(type, uid, options);

const queryByType = <T,>(
  type: DocumentType,
  options: QueryOptions = defaultOptions
) => Client().query<T>(predicates.at("document.type", type), options);

const customLink = (type, element, content, children, index) => (
  <Link
    key={index}
    href={linkResolver(element.data)}
  >
    <a>{content}</a>
  </Link>
)

export default { Client, predicates, queryByType, getByUID, customLink };
