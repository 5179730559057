interface Props {
  width?: number
  height?: number
  classNames?: string
}

export const ToggleCheckboxOn = ({ width = 15, height = 15 , classNames = 'icon' }: Props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 15 15"
      className={classNames}
    >
      <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
        <g transform='translate(-130 -82)'>
          <g transform='translate(130 82)'>
            <rect
              width='15'
              height='15'
              x='0'
              y='0'
              fill='#5282DE'
              rx='2'
            ></rect>
            <path
              fill='#FFF'
              d='M12.3206411 2.75L5.83249927 9.91857911 3.17935886 6.98497313 2 8.28818358 5.83249927 12.525 13.5 4.05321045z'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
