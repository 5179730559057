import { collection, doc, getDocs, setDoc, writeBatch } from 'firebase/firestore'
import { atom, selector, selectorFamily, useRecoilCallback, useRecoilStateLoadable, useRecoilValueLoadable } from 'recoil'
import firebase from '../firebase'
import { expandLoadableState, expandLoadableValue } from '../recoil'

export interface UserTopic {
  score: number
  explicit?: boolean
}

export const enum ScoreAction {
  OnboardTopic = 100,
  OnboardChallenge = 10,
  EnrollChallenge = 50,
  ViewContent = 5,
  ViewRecommendation = 25,
  CompleteTask = 40,
  CompleteChallenge = 80,
  PinContent = 35,
  LikeContent = 200,
  DislikeContent = -200,
  LikeTopic = 150,
  UnlikeTopic = -150,
  SearchTopic = 15,
  FilterTopic = 10,
}

interface PartialUserTopic {
  action?: ScoreAction
  score?: number
  explicit?: boolean
}

const initialUserTopic: UserTopic = {
  score: 0,
}

const loadUserTopics = async () => {
  const uid = await firebase.userUID
  const snapshot = await getDocs(collection(firebase.db, `users/${uid}/topics`))
  return snapshot.docs.reduce((userTopics, doc) => {
    userTopics[doc.id] = doc.data()
    return userTopics
  }, {})
}

const saveUserTopic = async (id: string, userTopic: UserTopic) => {
  const uid = await firebase.userUID
  await setDoc(doc(firebase.db, `users/${uid}/topics`, id), userTopic, { merge: false })
}

const saveUserTopics = async (userTopics: { [id: string]: UserTopic }) => {
  const uid = await firebase.userUID
  const batch = writeBatch(firebase.db)
  for (const [id, userTopic] of Object.entries(userTopics)) {
    const ref = doc(firebase.db, `users/${uid}/topics`, id)
    batch.set(ref, userTopic)
  }
  await batch.commit()
}

const userTopicsState = atom<{ [id: string]: UserTopic }>({
  key: 'userTopics',
  default: loadUserTopics(),
})

const userTopicQuery = selectorFamily<UserTopic, string>({
  key: 'userTopicQuery',
  get: id => async ({ get }) => get(userTopicsState)[id] ?? initialUserTopic,
  set: id => ({ get, set }, newValue) => {
    set(userTopicsState, { ...get(userTopicsState), [id]: newValue })
    saveUserTopic(id, newValue as UserTopic)
  },
})

export const useUserTopics = () => expandLoadableValue(useRecoilValueLoadable(userTopicsState))

export const useUserTopicState = (topicId: string) => expandLoadableState(useRecoilStateLoadable(userTopicQuery(topicId)))

export const userTopicsSorted = selector({
  key: 'userTopicsSorted',
  get: ({ get }) => (
    Object.entries(get(userTopicsState))
      .sort((a, b) => {
        return b[1].score - a[1].score
      })
      .slice(0, 9)
      .map(([id]) => id)
  )
})

export const useUserTopicsSorted = () => expandLoadableValue(useRecoilValueLoadable(userTopicsSorted))

export const userTopicsExplicit = selector({
  key: 'userTopicsExplicit',
  get: ({ get }) => (
    Object.entries(get(userTopicsState))
      .filter(([id, userTopic]) => userTopic.explicit)
      .map(([id]) => id)
  )
})

export const useUserTopicsExplicit = () => expandLoadableValue(useRecoilValueLoadable(userTopicsExplicit))

const userTopicsNotExplicit = selector({
  key: 'userTopicsNotExplicit',
  get: ({ get }) => (
    Object.entries(get(userTopicsState))
      .filter(([id, userTopic]) => !userTopic.explicit)
      .sort((a, b) => {
        return b[1].score - a[1].score
      })
      .slice(0, 4)
      .map(([id]) => id)
  )
})

export const useUserTopicsNotExplicit = () => expandLoadableValue(useRecoilValueLoadable(userTopicsNotExplicit))

export const useUserTopicCallback = () => useRecoilCallback(({ set, snapshot }) => async (changes: PartialUserTopic, topicIds: string[]) => {
  const userTopics = await snapshot.getPromise(userTopicsState)
  const newValues = topicIds.reduce((values: { [id: string]: UserTopic }, topicId) => {
    const userTopic = userTopics[topicId] ?? initialUserTopic
    if (changes.action) {
      changes.score = userTopic.score + changes.action
      delete changes.action
    }
    values[topicId] = {
      ...userTopic,
      ...changes,
    }
    return values
  }, {})
  set(userTopicsState, userTopics => ({
    ...userTopics,
    ...newValues
  }))
  saveUserTopics(newValues)
})

export const useUserTopicsDepreciator = () => useRecoilCallback(({ set, snapshot }) => async () => {
  const userTopics = await snapshot.getPromise(userTopicsState)
  const newValues = Object.entries(userTopics)
    .filter(([id, userTopic]) => userTopic.score > 1000)
    .reduce((newUserTopics, [id, userTopic]) => {
      const score = Math.floor((Math.log10(userTopic.score) - 3) * 1000 + 1000)
      newUserTopics[id] = { ...userTopic, score }
      return newUserTopics
    }, {})
  set(userTopicsState, userTopics => ({
    ...userTopics,
    ...newValues
  }))
  saveUserTopics(newValues)
})
