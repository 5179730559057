import { initializeApp } from 'firebase/app'
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const getConfig = () => (
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' ?
    require('../config/firebase-app.json') :
    require('../config/dev/firebase-app.json')
)

const app = initializeApp(getConfig())
const auth = getAuth(app)
const db = getFirestore(app)

const userUID = new Promise<string>(resolve => {
  onAuthStateChanged(auth, user => {
    if (user) resolve(user.uid)
  })
})

export default { app, auth, db, signOut: () => signOut(auth), userUID }

export const getServiceAccount = () => (
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' ?
    require('../config/firebase-admin.json') :
    require('../config/dev/firebase-admin.json')
)
