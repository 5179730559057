import { useChallengeContentMap, useUserContentsEnrolled } from '../../lib/state'
import { CompletionButton } from '../content'

interface Props {
  contentId: string
  topicIds: string[]
  format?: 'desktop' | 'mobile'
  children?: any,
}

export const CompletionWidget = ({ contentId, topicIds, format = 'desktop', children }: Props) => {
  const [map] = useChallengeContentMap()
  const [enrolledIds] = useUserContentsEnrolled()
  const challengeInfo = map && map[contentId] && enrolledIds && map[contentId].filter(info => enrolledIds.includes(info.id))[0]

  return !challengeInfo ? null : (
    <>
      {format === 'desktop' ? (
        <section className='rail-widget article-completion'>
          <div className='article-completion__body'>
            <h4 className='article-completion__title'>
              {children}
            </h4>
            <p className='article-completion__desc'>Mark this complete to check it off your Challenge.</p>
          </div>
          <CompletionButton contentId={challengeInfo.id} taskId={contentId} topicIds={topicIds} />
        </section>
      ) : (
        <CompletionButton
          contentId={challengeInfo.id}
          taskId={contentId}
          topicIds={topicIds}
          className='article-completion-mobile' />
      )}
    </>
  )
}
