import { ScoreAction, useAnalytics, useUserContentState, useUserTopicCallback } from '../../lib/state'
import { FieldValuesSelect } from '../forms'

const values = [
  {
    'id': 'dislike',
    'name': '👎'
  },
  {
    'id': 'like',
    'name': '👍'
  },
]

interface Props {
  contentId: string
  topicIds: string[]
}

export const Sentiment = ({ contentId, topicIds }: Props) => {
  const [userContent, setUserContent] = useUserContentState(contentId)
  const userTopicCallback = useUserTopicCallback()
  const analytics = useAnalytics()

  return (
    <FieldValuesSelect
      values={values}
      selected={userContent?.sentiment ?? ''}
      onSelect={sentiment => {
        setUserContent({ ...userContent, sentiment })
        const action = sentiment === 'like' ? ScoreAction.LikeContent : ScoreAction.DislikeContent
        userTopicCallback({ action }, topicIds)
        analytics('link', {
          'event_type': 'sentiment',
          'content_id': contentId,
          'sentiment': sentiment,
          'topic_ids': topicIds,
        })
      }}
      iconOff={false}
      iconOn={false}
      baseClassName='sentiment'
    />
  )
}
