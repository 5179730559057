import { motion, useMotionValue } from 'framer-motion'

interface Props {
  totalTasks: number;
  completedTasks: number;
  ringOnly?: boolean
  width?: number;
  height?: number

}

export const ProgressRing = ({ totalTasks, completedTasks, ringOnly = false, width = 246, height = 246 }: Props) => {

  const progress = completedTasks * 100 / totalTasks;
  const radius = 110;
  const circumference = radius * 2 * Math.PI;
  const strokeDashoffset = useMotionValue(0)
        strokeDashoffset.set(circumference - progress / 100 * circumference);
  const progressDegrees = useMotionValue((progress / 100) * 360);


  return (
    <>
    {/* {console.log('Progress Ring Re-Render', strokeDashoffset)} */}
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 246 246'
      className='progress-ring'
    >
      <g
        fill='none'
        fillRule='evenodd'
        stroke='none'
        strokeWidth='1'
      >
        <g transform='translate(-598 -512)'>
          <g id='challenge-tracker' transform='translate(598 512)'>
            <g
              id='progress-elements'
              className='progress-ring__circle-group'
            >
              <circle
                id='circle-bottom-track'
                className='progress-ring__circle-group-track'
                r={ radius }
                cx={ radius }
                cy={ radius }
              ></circle>
              <motion.circle
                id='circle-top-progress'
                className='progress-ring__circle-group-progress'
                r={ radius }
                cx={ radius }
                cy={ radius }
                strokeDasharray={ circumference + ' ' + circumference }
                // initial={{ strokeDashoffset: circumference}}
                initial={{ strokeDashoffset: strokeDashoffset.get() }}
                animate={{ strokeDashoffset: strokeDashoffset.get() }}
                transition={{duration: 0.6 }}
              ></motion.circle>
            </g>
            <motion.g
              id='indicator-dot-group'
              className='progress-ring__indicator-dot-rotator-box'
              initial={{ rotate: progressDegrees.getPrevious() }}
              animate={{ rotate: progressDegrees.get() }}
              transition={{duration: 0.6}}
            >
              <path
                id='indicator-bounding-box'
                fill='#FFF'
                fillOpacity='0'
                d='M0 0H246V246H0z'
              ></path>
              <circle
                id='indicator-dot'
                cx='122.5'
                cy='12.5'
                r='11.5'
                fill='#43BEAC'
              ></circle>
            </motion.g>
            {
              ringOnly ? '' :
              <g id='icon-count-label' transform='translate(10 34)'>
                <text
                  id='0/6'
                  fill='#43BEAC'
                  className='progress-ring__counter-count'
                  fontSize='64'
                >
                  <tspan x='65.48' y='119'>
                    {completedTasks}/{totalTasks}
                  </tspan>
                </text>
                <text
                  className='progress-ring__counter-label'
                  id='TASKS-COMPLETE'
                  fill='#1F2325'
                  fontSize='13'
                >
                  <tspan x='57.779' y='146'>
                    TASKS COMPLETE
                  </tspan>
                </text>
                <path
                  id='icon'
                  fill='#6A5086'
                  d='M119.396 0l-4.485 1.774-18.937 7.453 18.937 7.46v3.872l-8.531 14.773-4.243-7.358L86 55.93h54l-20.604-35.686V0zM108.22 9.227l6.69-2.632v5.265l-6.69-2.633zm.746 30.595l8.279-14.329 14.975 25.942H93.779l8.358-14.475 1.65 2.862 2.593 4.49 2.587-4.49z'
                ></path>
              </g>
            }
          </g>
        </g>
      </g>
    </svg>
    </>
  );
}
